import React, { useEffect, useState } from "react";
import { Modal, ModalMessage } from "../../../components";
import IconCheck from "../../../icons/IconCheck";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";
import { BotApi, TransactionResponse } from "../../../http";
import IconError from "../../../icons/IconError";
import { BotTransactionInterface } from "../../../interfaces";

const rotation = keyframes`

  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.span`

  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
`


export const ConfirmationModal: React.FC<{ telegram_id: number, message_id: number, onClose: () => void }> = (
  {
    telegram_id,
    message_id,
    onClose,
  }
) => {

  const [transaction, setTransaction] = useState<BotTransactionInterface>()
  const [hasError, setHasError] = useState(false)

  useEffect(() => {

    if (hasError || transaction?.closed) {
      setTimeout(() => {

        onClose()

      }, 2000)
    }

  }, [transaction, hasError]);

  useEffect(() => {

    const _int = setInterval(() => {

      (async () => {

        try {
          const response = await BotApi.getTransaction(message_id, telegram_id)

          setTransaction(response._embedded)

          if (response._embedded.closed) {
            clearInterval(_int)
          }

        } catch (e) {
          setHasError(true)
          clearInterval(_int)
        }

      })()

    }, 2000)


    return () => {
      try {
        clearInterval(_int)
      } catch (e) {

      }
    }

  }, [telegram_id, message_id]);

  return <>
    <Modal
      opened={true}
      hideEveryClick={true}
      onHide={() => onClose}>
      {

        hasError ? (
          <ModalMessage
            Icon={IconError}
            iconColor={"var(--text-error)"}
            message={"Не удалось найти информацию о транзакции"}
          />
        ) : (
          <ModalMessage
            Icon={transaction?.closed ? IconCheck : Loader}
            iconColor={"var(--text-success)"}
            message={transaction?.closed ? "Клиент подтвердил" : "Клиенту отправлено письмо в телеграм с кнопкой подтверждения, подождите, пока клиент не нажмет на него"}
          />
        )

      }
    </Modal>
  </>
}
