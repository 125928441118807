import styled from "@emotion/styled";
import React from "react";

const Wrapper = styled.div`
  color: var(--text-color);
  width: 90vw;
  border-radius: var(--border-radius-normal);
  padding: var(--space-gap-3x);
  background: var(--bg-color);
`

const FeedbackWrapperTable = styled.table`
  width: 100%;
  border: 0;

  td, th {
    border: 0;
  }
`

const FeedbackWrapper: React.FC<{ children: any }> = (props) => {
    return <Wrapper>
        <FeedbackWrapperTable>
            <tbody>
            {props.children}
            </tbody>
        </FeedbackWrapperTable>
    </Wrapper>
}

export {FeedbackWrapper}

export default FeedbackWrapper