import styled from "@emotion/styled";
import React, {useMemo} from "react";
import {HeaderProps} from "./types";
import IconUserCircle from "../../icons/IconUserCircle";

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-end;
`

const StyledTitle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`
const Phone = styled.div`
  font-size: 18px;
  font-weight: 600;
`
export const Header: React.FC<HeaderProps> = ({ phone, title }) => {
    const normalizedPhone = useMemo(() => {
        if (!phone) return title
        const phoneArray = phone.split('')
        return `+${phoneArray[0]} (${phoneArray.slice(1, 4).join("")}) ${phoneArray.slice(4, 7).join("")}-${phoneArray.slice(7, 9).join("")}-${phoneArray.slice(9, 12).join("")}`
    }, [phone, title])

    return <StyledHeader>
        <StyledTitle><IconUserCircle width={25}/><Phone>{normalizedPhone}</Phone></StyledTitle>
    </StyledHeader>
}