import styled from "@emotion/styled";

export const BottomNavigator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  height: 60px;
  box-shadow: 0 -1px 0 0 var(--secondary-bg-color);
  width: 100%;
  backdrop-filter: blur(20px);
  //position: sticky;
  position: fixed;
  bottom: 0;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--secondary-bg-color);
    opacity: 0.7;


  }
  
`