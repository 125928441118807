import styled from "@emotion/styled";
import {StickyContainer} from "../StickyContainer";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
  flex: 1;
  & .balance-container {
    margin: 0 -10px 0 -10px;
    padding: 10px 10px 10px 10px;
    background: var(--secondary-bg-color);
    border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  }
  & .user-container {
    margin: 0 -10px;
    padding: 10px;
    background: var(--secondary-bg-color);
  }
  
`