import create from "zustand";
import {devtools} from "zustand/middleware";
import {FreshbackApi} from "../../http";
interface FreshbackApiStoreInterface {
    api: FreshbackApi,
    setApi: (state: FreshbackApi) => any
}

export const useFreshbackApi = create(devtools<FreshbackApiStoreInterface>((set) => ({
    api: new FreshbackApi({
        "id": 0,
        "login": "",
        "accessToken": "",
        "refreshToken": "",
        "roles": [],
        "corporateSalesUnitIds": [],
        "tenantProfileId": 0,
        "employeeSalesUnitIds": [],
        "fingerprint": "",
    }),
    setApi: (state: FreshbackApi) => {
        set({api: state})
    }
})))
