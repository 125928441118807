import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Modal, ModalMessage, TextArea} from "../../components";
import {OrderFeedbackProps} from "./types";
import {Loader} from "../../components/Loader";
import {useFreshbackApi} from "../../Business/stores/useFreshbackApi";
import IconCheck from "../../icons/IconCheck";
import IconError from "../../icons/IconError";
import {OrderResponse} from "../../http";
import {
    FeedbackWrapper,
    FeedbackFormItem,
    FeedbackFormItemTitle,
    FeedbackFormItemValue,
    FeedbackRate
} from "./components";
import { useNotifications } from "../../Business/stores/useNotifications";

const OrderFeedback: React.FC<OrderFeedbackProps> = ({orderId, vendorName}) => {
    const {api} = useFreshbackApi()
    const {notifications, normalizedNotifications, loading: notificationLoading, setLoading: setNotificationLoading, setNotifications} = useNotifications()

    const [modalOpened, setModalOpened] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [orderError, setOrderError] = useState(false)
    const [order, setOrder] = useState<OrderResponse | null>(null)

    const [feedbackRate, setFeedbackRate] = useState<number>(0)
    const [comment, setComment] = useState<string>("")

    useEffect(() => {
        if (success) {
            setNotificationLoading(true)
            api.getNotifications().then((response) => {
                setNotifications(response)
            }).finally(() => setNotificationLoading(false))
        }
    }, [success])

    useEffect(() => {
        setLoading(true)
        if (modalOpened) {
            api.getOrder(orderId).then((response) => {
                setOrder(response)
            }).catch(() => {
                setOrderError(true)
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setSuccess(false)
            setOrderError(false)
            setOrder(null)

            setComment("")
            setFeedbackRate(-1)
        }
    }, [modalOpened])

    const isValid = useMemo(() => {
        return feedbackRate > 0
    }, [comment, feedbackRate])

    const onSubmit = useCallback(() => {
        if (!isValid) return false
        setLoading(true)

        api.request.post("/api/tenant-order-feedbacks", {
            "score": feedbackRate,
            "note": comment,
            "orderId": orderId,
            "blocked": false,
            // "vendorId": order?.salesUnitId,
            "customerId": api.tenantProfileId
        }).then(() => {
            setSuccess(true)

            setTimeout(() => {
                setModalOpened(false)
            }, 1000)
        }).catch(() => {
            setOrderError(true)
        }).finally(() => {
            setLoading(false)
        })
    }, [api, isValid, comment, feedbackRate, order])

    return <>
        <Modal opened={modalOpened} onHide={() => setModalOpened(false)}>
            {loading && <Loader withoutContainer={true}/>}
            {success &&
                <ModalMessage Icon={IconCheck} iconColor={"var(--text-success)"} message={"Спасибо за отзыв!"}/>}
            {orderError &&
                <ModalMessage Icon={IconError} iconColor={"var(--text-error)"} message={"Произошла ошибка"}/>}
            {!loading && !orderError && !success && <>
                <FeedbackWrapper>
                    <FeedbackFormItem>
                        <FeedbackFormItemTitle>
                            Название ТТ
                        </FeedbackFormItemTitle>
                        <FeedbackFormItemValue>
                            {order?.salesUnit.name}
                        </FeedbackFormItemValue>
                    </FeedbackFormItem>

                    {/*<FeedbackFormItem>*/}
                    {/*    <FeedbackFormItemTitle>*/}
                    {/*        Дата*/}
                    {/*    </FeedbackFormItemTitle>*/}
                    {/*    <FeedbackFormItemValue>*/}
                    {/*        {moment(order.createdAt * 1000).format("Do MMMM YYYY г.")}*/}
                    {/*    </FeedbackFormItemValue>*/}
                    {/*</FeedbackFormItem>*/}

                    <FeedbackFormItem>
                        <FeedbackFormItemTitle>
                            Оценка
                        </FeedbackFormItemTitle>
                        <FeedbackFormItemValue>
                            <FeedbackRate count={5} value={feedbackRate} onChange={(num) => setFeedbackRate(num)}/>
                        </FeedbackFormItemValue>
                    </FeedbackFormItem>

                    <>
                        <FeedbackFormItem>
                            <FeedbackFormItemTitle>
                                Комментарий
                            </FeedbackFormItemTitle>
                        </FeedbackFormItem>
                        <FeedbackFormItem>
                            <FeedbackFormItemValue colSpan={2}>
                                <TextArea
                                    placeholder={"..."}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value.trimStart())}
                                />
                            </FeedbackFormItemValue>
                        </FeedbackFormItem>
                    </>

                    <FeedbackFormItem>
                        <FeedbackFormItemValue colSpan={2}>
                            <Button disabled={!isValid} onClick={onSubmit}>Оставить отзыв</Button>
                        </FeedbackFormItemValue>
                    </FeedbackFormItem>
                </FeedbackWrapper>
            </>}
        </Modal>
        <Button onClick={() => setModalOpened(true)}>Оставьте пожалуйста отзыв</Button>
    </>
}

export {OrderFeedback}

export default OrderFeedback
