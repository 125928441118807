import React, {useEffect, useRef, useState} from "react";
import env from "../../../data/env";
import {Iframe} from "../../../components";
import {useUser} from "../../stores/useUser";
import botApi, {BotApi} from "../../../http/botApi";
import {UserResponse} from "../../../responses/UserResponse";

export const SearchPage: React.FC = () => {
    const externalUser = useUser((state) => state.externalUser)
    const user = useUser((state) => state.user)
    const setUser = useUser((state) => state.setUser)
    const ref = useRef<HTMLIFrameElement>(null)

    const [userData, setUserData] = useState<UserResponse>()

    useEffect(() => {
        if (!userData) {
            BotApi.getMe().then(item => {
                setUserData(item as UserResponse)
            })
        }

        if (ref.current) {



            ref.current.onload = (e) => {
                // user.activePageData ? user.activePageData :
                botApi.setOnPage("SearchHome",  "/offline").then(item => setUser(item))


                // window.addEventListener('message', (message) => {
                //     if (message && message.data && message.data.type && message.data.type === "page_change") {
                //         botApi.setOnPage("SearchHome", message.data.payload)//.then(item => setUser(item))
                //     }
                // })

            }

        }
    }, [userData]);

    const page = user.activePageData ? user.activePageData : "/offline"
    const query = `?iframe=1&access_token=${userData?.external.accessToken}&fingerprint=${userData?.external.fingerprint}&refresh_token=${userData?.external.refreshToken}`




    return <div style={{flex: 1}}>
        {userData && <Iframe
          style={{width: "calc(100% + 20px)", margin: "0 -10px"}}
          src={env.onlineShopsUrl + page + query}
          ref={ref}
        >

        </Iframe>}
    </div>

}
