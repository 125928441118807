import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import freshBackLogo from "../../../icons/svg/Freshback.svg"
import { useUser } from "../../stores/useUser";
import { Button, useTelegramWebApp } from "../../../components";
import env from "../../../data/env";


const qrCode = new QRCodeStyling(
  {
    "width": 300,
    "height": 300,
    "data": "https://my.scrm.dtpool.ru/shops/registration?ref_mode=t&ref_id=",
    // "margin": 0,
    "qrOptions": {  "mode": "Byte", "errorCorrectionLevel": "Q" },
    "imageOptions": {
      "hideBackgroundDots": true,
      "imageSize": 0.2,
      "margin": 2
    },
    "dotsOptions": {
      "type": "extra-rounded",
      "color": "#df3939",
      "gradient": {
        "type": "linear",
        "rotation": 0,
        "colorStops": [{ "offset": 0, "color": "#2c5887" }, { "offset": 1, "color": "#df3939" }]
      }
    },
    "backgroundOptions": { "color": "#17212b" },
    "image": freshBackLogo,

    // "dotsOptionsHelper": {
    //   "colorType": { "single": true, "gradient": false },
    //   "gradient": { "linear": true, "radial": false, "color1": "#6a1a4c", "color2": "#6a1a4c", "rotation": "0" }
    // },
    "cornersSquareOptions": {
      "type": "extra-rounded",
      "color": "#df3939",
      "gradient": {
        "type": "linear",
        "rotation": 0.6108652381980153,
        "colorStops": [{ "offset": 0, "color": "#2c5887" }, { "offset": 1, "color": "#df3939" }]
      }
    },
    // "cornersSquareOptionsHelper": {
    //   "colorType": { "single": true, "gradient": false },
    //   "gradient": { "linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0" }
    // },
    "cornersDotOptions": {
      "type": "dot",
      "color": "#000000",
      "gradient": {
        "type": "linear",
        "rotation": 0.6108652381980153,
        "colorStops": [{ "offset": 0, "color": "#2c5887" }, { "offset": 1, "color": "#df3939" }]
      }
    },
    // "cornersDotOptionsHelper": {
    //   "colorType": { "single": true, "gradient": false },
    //   "gradient": { "linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0" }
    // },
    // "backgroundOptionsHelper": {
    //   "colorType": { "single": true, "gradient": false },
    //   "gradient": { "linear": true, "radial": false, "color1": "#ffffff", "color2": "#ffffff", "rotation": "0" }
    // }
  });
export const QrCodePage: React.FC = () => {

  const ref = useRef(null);
  const user = useUser(state => state.externalUser)
  const tg = useTelegramWebApp()
  const [accent, setAccent] = useState(false)
  useEffect(() => {
    ref.current && qrCode.append(ref.current)

  }, [qrCode]);

  useEffect(() => {
    qrCode.update({
      backgroundOptions: {
        color: accent ? tg.themeParams.text_color : tg.themeParams.bg_color
      }
    })
  }, [tg, accent]);


  useEffect(() => {
    qrCode.update({
      data: env.qrCodeUrl + user.tenantProfileId
    });
  }, [user]);

  return <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", paddingBottom: 20 }}>
    <div style={{flex: 1, display: "flex", alignItems: "center"}}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }} ref={ref}/>
    </div>
    <Button onClick={() => setAccent(p => !p)}>Сделать акцентным</Button>
  </div>

}
