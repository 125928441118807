import React from 'react';
import styled from "@emotion/styled";
import IconLogotype from "../../icons/IconLogotype";
import {keyframes} from "@emotion/css";

const fading = keyframes`
  0% {
    opacity: 0.6;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-color);
`
const Icon = styled.div`
  display: inline-block;
  animation: ${fading} 2s ease infinite;
`

export const Loader: React.FunctionComponent<{ withoutContainer?: boolean }> = ({withoutContainer}) => {
    const Wrapper = withoutContainer ? React.Fragment : Container

    return <Wrapper>
        <Icon><IconLogotype color={"#fff"} fill={"var(--logo-freshback-color)"} width={50}/></Icon>
    </Wrapper>

}

