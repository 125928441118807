import React, { memo } from 'react';
import { IconProps } from "./IconLogotype";

const IconQr: React.FunctionComponent<IconProps> = ({ width = 24, color = "currentColor" }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24"
              fill={color}>
      <path
        d="M3 11h8V3H3zm2-6h4v4H5zM3 21h8v-8H3zm2-6h4v4H5zm8-12v8h8V3zm6 6h-4V5h4zm-5.99 4h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm4 0h2v2h-2zm2 2h2v2h-2zm-4 0h2v2h-2zm2-6h2v2h-2zm2 2h2v2h-2z" />
  </svg>
};

export default memo(IconQr)
