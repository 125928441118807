import React, {useCallback, useEffect, useRef} from "react";
import {createPortal} from "react-dom";
import {ModalProps, ModalSizes} from "./types";
import styled from "@emotion/styled";

const ModalContainer = styled.div`
  display: block;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
`

const ModalOverlay = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--secondary-bg-color);
  opacity: 0.4;
  z-index: 1;
`

const ModalBody = styled.div<{ modalSizes?: ModalSizes }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 2;
  opacity: 0;
  box-sizing: border-box;
  width: ${props => {
    switch (props.modalSizes) {
      case ModalSizes.SMALL:
        return "35vw"
      case ModalSizes.MEDIUM:
        return "50vw"
      case ModalSizes.LARGE:
        return "75vw"
      default:
        return "auto"
    }
  }};
`

const Modal: React.FC<ModalProps> = (props) => {

    const containerRef = useRef<HTMLDivElement>(null)
    const bodyRef = useRef<HTMLDivElement>(null)

    const doSizeBody = useCallback(() => {
        if (!bodyRef.current || !containerRef.current)
            return false

        if (bodyRef.current.offsetHeight > containerRef.current.offsetHeight) {
            bodyRef.current.style.transition = "none"

            bodyRef.current.style.top = bodyRef.current.style.opacity === "0" ? "15%" : "0"
            bodyRef.current.style.transform = "translate(-50%, 0%)"
            containerRef.current.style.overflow = "auto"
        } else {
            bodyRef.current.style.transition = "all 150ms ease-in-out"
            bodyRef.current.style.top = "50%"
            bodyRef.current.style.transform = "translate(-50%, -50%)"
            containerRef.current.style.overflow = "hidden"
        }

        bodyRef.current.style.opacity = "1"

    }, [])

    useEffect(() => {

        let _timer: undefined | NodeJS.Timer = undefined
        let _timer2: undefined | NodeJS.Timer = undefined
        if (props.opened) {

            _timer = setInterval(doSizeBody, 1000)
            _timer2 = setInterval(() => {
                if (bodyRef.current && containerRef.current) {
                    clearInterval(_timer2)
                    doSizeBody()
                }
            }, 100)

        }

        return () => {
            if (_timer) {
                clearInterval(_timer)
            }
            if (_timer2) {
                clearInterval(_timer2)
            }
        }
    }, [props.opened])

    if (!props.opened) {
        return <></>
    }

    return createPortal(<ModalContainer ref={containerRef}>
        <ModalBody onClick={() => props.hideEveryClick && props.onHide()} modalSizes={props.size} ref={bodyRef}>
            {props.children}
        </ModalBody>
        <ModalOverlay onClick={props.onHide}/>
    </ModalContainer>, document.body)
}

export {Modal}
export * from "./components"

export default Modal