import React, {memo} from 'react';
import {IconProps} from "./IconLogotype";

const IconCodeCurly: React.FunctionComponent<IconProps> = ({width = 24, color = "currentColor"}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24"
                fill={color}>
        <path
            d="M9 22h1v-2h-.989C8.703 19.994 6 19.827 6 16c0-1.993-.665-3.246-1.502-4C5.335 11.246 6 9.993 6 8c0-3.827 2.703-3.994 3-4h1V2H8.998C7.269 2.004 4 3.264 4 8c0 2.8-1.678 2.99-2.014 3L2 13c.082 0 2 .034 2 3 0 4.736 3.269 5.996 5 6zm13-11c-.082 0-2-.034-2-3 0-4.736-3.269-5.996-5-6h-1v2h.989c.308.006 3.011.173 3.011 4 0 1.993.665 3.246 1.502 4-.837.754-1.502 2.007-1.502 4 0 3.827-2.703 3.994-3 4h-1v2h1.002C16.731 21.996 20 20.736 20 16c0-2.8 1.678-2.99 2.014-3L22 11z"></path>
    </svg>
};

export default memo(IconCodeCurly)