import styled from "@emotion/styled";

const FeedbackFormItem = styled.tr`
  //gap: var(--space-gap-2x);
`

export {
    FeedbackFormItem
}

export default FeedbackFormItem