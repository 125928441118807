import create from "zustand";
import {devtools} from "zustand/middleware";
import {Setter} from "../../types";
import {TransactionProps} from "../../components/TransactionCard/types";
import {SortedByDateItemsInterface} from "../../utils";

interface TransactionStoreInterface {
    transactions: SortedByDateItemsInterface<TransactionProps>,
    loading: boolean,
    setLoading: (state: boolean) => void,
    setTransactions: (state: Setter<SortedByDateItemsInterface<TransactionProps>>) => any,
}

export const useTransactions = create(devtools<TransactionStoreInterface>((set, get) => ({
    transactions: {},
    loading: true,
    setLoading(state){
        set({
            loading: state
        })
    },
    setTransactions: (state) => {
        if (typeof state === "function") {
            set({
                transactions: state(get().transactions)
            })
        } else {
            set({
                transactions: state
            })
        }
    }
})))
