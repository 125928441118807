import create from "zustand";
import {devtools} from "zustand/middleware";
import {Setter} from "../../types";
import {getSortedByDate, SortedByDateItemsInterface} from "../../utils";
import {NotificationCardProps} from "../../components/NotificationCard/types";
import {NotificationResponse} from "../../http/freshbackApi/responses/NotificationResponse";
import {FreshbackApi} from "../../http";


interface NotificationsStoreInterface {
    notifications: NotificationResponse[],
    setNotifications: (state: Setter<NotificationResponse[]>) => any,
    loading: boolean,
    setLoading: (state: boolean) => void,
    normalizedNotifications: SortedByDateItemsInterface<NotificationCardProps>
}

export const useNotifications = create(devtools<NotificationsStoreInterface>((set, get) => ({
    notifications: [],
    loading: true,
    setLoading(state){
        set({
            loading: state
        })
    },
    normalizedNotifications: {},
    setNotifications: (state) => {
        let newState

        if (typeof state === "function") {
            newState = state(get().notifications)
        } else {
            newState = state
        }

        set({
            notifications: newState,
            normalizedNotifications: FreshbackApi.getNormalizeNotifications(newState)
        })
    }
})))
