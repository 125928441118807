import styled from "@emotion/styled";
import React, {useMemo} from "react";
import {TransactionProps} from "./types";
import {css} from "@emotion/react";
import IconRub from "../../icons/IconRUB";
import IconLogotype from "../../icons/IconLogotype";
import {stat} from "fs";
import {TRANSACTIONS_STATUS_TYPE} from "../../data/constant";
import { formatNumber, getMoment } from "../../utils";
import x2 from "./images/2x.png"

const CardContainer = styled.div`
  margin: var(--space-gap-3x) 0;
`
const Wrapper = styled.div`
  position: relative;
`
const CardDate = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: var(--hint-color);

  position: absolute;
  right: 0;
  bottom: 0;
  margin: var(--space-gap-3x) var(--space-gap-4x);
`

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: var(--space-gap-3x);
  max-width: calc(100% - 90px);
`


const CardAddRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  & > span {
    width: calc(50% - 5px);
  }
`


const CardAddColumnContainer = styled.div<{status: string}>`
  position: relative;
  border-radius: var(--border-radius-normal);
  padding: var(--space-gap-3x);
  background: ${({status}) => status ? `var(--radial-gradient-transaction-card--${status})` : "var(--secondary-bg-color)"};
`

const CardNote = styled.div`
  font-size: 14px;
  color: var(--hint-color);
  margin-top: var(--space-gap-2x);
  
`
const Status = styled.div<{status: string}>`
  position: absolute;
  margin: var(--space-gap-3x) var(--space-gap-4x);
  right: 0;
  top: 0;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: ${({status}) => status ? `var(--text-color--${status})` : "var(--secondary-bg-color)"};
`
const ValueText = styled.div`
  font-size: 17px;
  display: inline-flex;
  align-items: center;
  
  & > svg {
    margin-left: 4px;
  }
`
const CardValue = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-gap);
  font-size: 16px;
  font-weight: 500;
`
const ActionValue = styled.div`
  display: inline-block;
  padding: 2px 5px;
  font-weight: 600;
  background-color: var(--backgroung-action-value);
  border-radius: calc(var(--border-radius-small) / 1.5);
`
const CardValueAction = styled(CardValue)`
  font-size: 14px;
  font-weight: 500;
`
const CardValueCashback = styled.div`
  font-size: 20px;
  font-weight: 600;
`
const CardValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-gap-2x);
`

const CardTitleContainer = styled.div`
  display: flex;
  gap: var(--space-gap-2x);
`
const TEN = 1673298000

export const TransactionCard: React.FC<TransactionProps> = ({ expectedReceiptDate, type, date, dateNum, status_id, sum, status, sale, note, shopName, cashback }) => {
    const moreThenTen = useMemo(() => {
        return getMoment(dateNum).diff(getMoment(TEN), 'days') >= 0
    }, [dateNum])
    return <CardContainer>
        <Wrapper>

            <CardAddColumnContainer status={TRANSACTIONS_STATUS_TYPE[status_id].en_name}>
                <Status status={TRANSACTIONS_STATUS_TYPE[status_id].en_name}>{status}</Status>
                <CardTitle>{shopName}</CardTitle>
                <CardAddRowContainer>
                    <CardValueContainer>
                        <CardValue>
                            {type === 4 ? <IconLogotype width={24} color={"#fff"} fill={"var(--logo-freshback-color)"}/> : <IconRub color={"var(--hint-color)"}/>}
                            <ValueText>
                                <span>{formatNumber(sum)}</span>{type !== 4 && <>&#8239;&#8381;</>}<span style={{marginLeft: 7, fontSize: '0.7em'}}>{expectedReceiptDate}</span>
                            </ValueText>
                        </CardValue>
                        <CardValueAction>
                            Акция <ActionValue><span>{formatNumber(sale)}%</span></ActionValue>
                            {moreThenTen && <img style={{width: 25}} src={x2}/>}
                        </CardValueAction>
                        <CardDate>{date}</CardDate>
                    </CardValueContainer>
                    <div>
                        <CardValue>
                            <CardValueCashback>+{formatNumber(cashback)}</CardValueCashback>
                            <IconLogotype width={21} color={"#fff"} fill={"var(--logo-freshback-color)"}/>
                        </CardValue>
                    </div>

                </CardAddRowContainer>
                {note && <CardNote>
                    Заметка: {note}
                </CardNote>}
            </CardAddColumnContainer>
        </Wrapper>

    </CardContainer>
}
