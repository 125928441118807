import styled from "@emotion/styled";

const FeedbackFormItemValue = styled.td`
  padding: var(--space-gap-2x) var(--space-gap);
`

export {
    FeedbackFormItemValue
}

export default FeedbackFormItemValue