import React, {useEffect, useState} from "react";
import {useUser} from "../Business/stores/useUser";
import {BotApi, FreshbackApi, SalesUnitResponse} from "../http";
import {useFreshbackApi} from "../Business/stores/useFreshbackApi";
import {Loader} from "../components/Loader";
import {useSalesUnits} from "../Business/stores/useSalesUnits";
import {AppMessage, useTelegramWebApp} from "../components";
import IconLogotype from "../icons/IconLogotype";
import {Login} from "../features";
import {BotEnums} from "../types";


const AuthContext: React.FunctionComponent<{ children: any }> = (props) => {
    const setApi = useFreshbackApi((state) => state.setApi)
    const [user, setUser] = useUser((state) => [state.user, state.setUser])
    const setExternalUser = useUser((state) => state.setExternalUser)
    const setTokens = useUser((state) => state.setTokens)
    const { setSalesUnits }= useSalesUnits()

    const [loading, setLoading] = useUser((state) => [state.loadingUser, state.setLoading])
    const [error, setError] = useUser((state) => [state.error, state.setError])
    const [showLoginForm, setShowLoginForm] = useState<boolean>(false)
    const webapp = useTelegramWebApp()

    useEffect(() => {
        webapp.expand()

        if (user.telegram_id === 0) {
            setLoading(true)

            BotApi.getMe().then(async (response) => {
                const api = new FreshbackApi(response.external)
                let _response: SalesUnitResponse[] = []

                try {
                    if (BotApi.bot_type === BotEnums.EMPLOYEE && response.external.employeeSalesUnitIds) {
                        _response = await api.getSalesUnitsByIds(response.external.employeeSalesUnitIds)
                    } else if (BotApi.bot_type === BotEnums.TENANT) {
                        _response = await api.getSalesUnits()
                    }
                    console.log(response)
                    setUser(response.user)
                    //@ts-ignore
                    setTokens(response.tokens)
                    setSalesUnits(_response)
                    setApi(api)
                    setExternalUser(response.external)
                } catch (e) {
                    setError(true)
                } finally {
                    setLoading(false)
                }

            }).catch(() => {
                setLoading(false)
                setError(true)
            })

        }

    }, [user])

    if (loading) {
        return <Loader />
    }

    if (showLoginForm) {
        return <Login />
    }

    if (error) {
        return <div style={{background: "var(--bg-color)", height: "var(--tg-viewport-height, 100vh)"}}>
            <AppMessage
                style={{height: "var(--tg-viewport-height, 100vh)"}}
                icon={<IconLogotype style={{ cursor: "pointer" }} onClick={() => setShowLoginForm(true)} color={"#fff"} fill={"var(--logo-freshback-color)"}/>}
                text={"Вы не авторизованы!"}
            />
        </div>
    }

    return <>
        {props.children}
    </>
}

export { AuthContext }
