import React, { useCallback, useRef, useState } from "react";
import {LoginProps} from "./types";
import {Button, FlexContainer, Header, Modal, ModalMessage, SectionContainer} from "../../components";
import {StickyContainer} from "../../components/StickyContainer";
import {css} from "@emotion/css";
import Input from "../../components/Input";
import PhoneInput from "../../components/PhoneInput";
import styled from "@emotion/styled";
import axios from "axios";
import {ExternalUserInterface} from "../../interfaces";
import IconError from "../../icons/IconError";
import {BotApi, FreshbackApi, SalesUnitResponse} from "../../http";
import {BotEnums} from "../../types";
import {useFreshbackApi} from "../../Business/stores/useFreshbackApi";
import {useUser} from "../../Business/stores/useUser";
import {useSalesUnits} from "../../Business/stores/useSalesUnits";
import {getNormalizedPhone} from "../../utils";
import ReCAPTCHA from "react-google-recaptcha";
import env from "../../data/env";

const FlexContainerCss = css`
  //padding: 30px;
  align-items: center;
  justify-content: center;
`

const FlexContainer2 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
`

const Login: React.FC<LoginProps> = (props) => {
    const [userPhone, setUserPhone] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState<boolean>(false)
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const [valid, setValid] = useState<boolean>(false)

    const setApi = useFreshbackApi((state) => state.setApi)
    const [setUser] = useUser((state) => [state.setUser])
    const setExternalUser = useUser((state) => state.setExternalUser)
    const setTokens = useUser((state) => state.setTokens)
    const { setSalesUnits }= useSalesUnits()

    const onSubmit = useCallback(() => {
        const phone = getNormalizedPhone(userPhone)
        const token = recaptchaRef.current?.getValue() || ''
        recaptchaRef.current?.reset();

        axios.post<ExternalUserInterface>(process.env.REACT_APP_FRESHBACK_API_URL + "/api/authentication/login", {
            "login": phone,
            "password": password,
            "fingerprint": navigator.userAgent,
            "token": token
        }).then(async (response) => {
            if (BotApi.checkRights(BotApi.bot_type, response.data.roles)) {
                const api = new FreshbackApi(response.data)

                if (BotApi.bot_type === BotEnums.EMPLOYEE && response.data.employeeSalesUnitIds) {
                   await api.getSalesUnitsByIds(response.data.employeeSalesUnitIds)
                }
                localStorage.setItem("refreshToken" + BotApi.bot_type, response.data.refreshToken)
                localStorage.setItem("accessToken" + BotApi.bot_type, response.data.accessToken)

                window.location.reload()

                // setSalesUnits(_response)
                // setApi(api)
                // setExternalUser(response.data)
                // setUser({
                //     telegram_id: 1,
                //     phone: phone,
                //     bot_type: BotApi.bot_type
                // })
                // setTokens({
                //     accessToken: response.data.accessToken,
                //     refreshToken: response.data.refreshToken
                // })

            } else {
                setError(true)
            }
        }).catch(() => {
            setError(true)
        })
    }, [userPhone, password])

    return <FlexContainer>

        <SectionContainer>
            <StickyContainer className={"user-container"}>
                <Header title={"Авторизация"}/>
            </StickyContainer>
            <FlexContainer className={FlexContainerCss}>

                <FlexContainer2>
                    <Modal opened={error} onHide={() => setError(false)}>
                        <ModalMessage Icon={IconError} iconColor={"var(--text-error)"} message={"Не удалось войти"}/>
                    </Modal>
                    <PhoneInput
                        label={"Телефон"}
                        placeholder={"+7 999 999 99 99"}
                        value={userPhone}
                        onChange={(e) => setUserPhone(e.target.value.trimStart())}
                        style={{width: "80vw"}}
                    />
                    <Input
                        label={"Пароль"}
                        type={"password"}
                        placeholder={"Пароль"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{width: "80vw"}}
                    />
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={env.captchaSiteKey}
                      onChange={() => setValid(true)}
                      onExpired={() => setValid(false)}
                    />

                    <Button disabled={!valid} onClick={onSubmit}>Войти</Button>
                </FlexContainer2>

            </FlexContainer>
        </SectionContainer>

    </FlexContainer>
}

export {Login}

export default Login
