import React, {HTMLAttributes, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {FeedbackRateProps} from "./types";
import styled from "@emotion/styled";
import mojs, {Shape} from "@mojs/core";

const RateContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: var(--space-gap-2x);

  svg {
    cursor: pointer;

    &:not(.Rate-element) {
      pointer-events: none;
    }
  }

  div[data-name="mojs-shape"] {
    pointer-events: none;
  }

  .Rate-element {
    position: relative;
    z-index: 999;
    transition: all 150ms ease-in-out;
  }


`

const RateStar: React.FC<HTMLAttributes<HTMLOrSVGElement> & { active?: boolean }> = ({onClick, active, ...props}) => {
    const animDom = useRef<HTMLDivElement>(null)
    const burst = useRef<Shape>()
    const bubbles = useRef<Shape>()
    useEffect(() => {
        if (!animDom.current) return;
        if (!burst.current) {
            burst.current = new mojs.Burst({
                // left: 0, top: 0,
                radius: {0: 30},
                angle: 'rand(0, 360)',
                parent: animDom.current,
                children: {
                    shape: 'line',
                    stroke: ['#F9DD5E', '#FC2D79', '#11CDC5'],
                    fill: 'none',
                    scale: 1,
                    scaleX: {1: 0},
                    easing: 'cubic.out',
                    duration: 1000
                }
            })
        }

        if (!bubbles.current) {
            bubbles.current = new mojs.Burst({
                // left: 0, top: 0,
                radius: 28,
                count: 3,
                timeline: {delay: 100},
                parent: animDom.current,
                children: {
                    stroke: ['#F9DD5E', '#FC2D79', '#11CDC5'],
                    fill: 'none',
                    scale: 1,
                    strokeWidth: {8: 0},
                    radius: {0: 'rand(6, 10)'},
                    degreeShift: 'rand(-50, 50)',
                    duration: 400,
                    delay: 'rand(0, 250)',
                }
            })
        }

    })

    const animateCallBack = useCallback(() => {
        if (animDom.current && burst.current && bubbles.current) {
            animDom.current.style.transition = "all 150ms ease-in-out"
            animDom.current.style.transform = "scale(0.9)"

            setTimeout(() => {
                animDom.current!.style.transform = "scale(1.25)"
            }, 250)
            setTimeout(() => {
                animDom.current!.style.transform = "scale(1)"
            }, 500)

            burst
                .current
                .replay()

            bubbles
                .current
                .replay()
        }
    }, [])

    return <div
        ref={animDom}
        style={{position: "relative", overflow: "visible"}}
    >
        <svg
            className={"Rate-element"}
            {...props}
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="star"
            width="25px"
            height="25px"
            fill={active ? "var(--text-waiting)" : "var(--text-color)"}
            aria-hidden="true"
            onClick={(e) => {
                if (onClick) {
                    onClick(e)
                }
                animateCallBack()
            }}
        >
            <path
                d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
        </svg>
    </div>
}

const FeedbackRate: React.FC<FeedbackRateProps> = (props) => {
    const [hoveredInto, setHoveredInto] = useState<number>(props.value ? (props.value - 1) : -1)

    const _arr = useMemo(() => {
        return Array.from(Array(props.count).keys())
    }, [props.count])

    return <RateContainer
        onMouseLeave={() => !props.value ? setHoveredInto(-1) : setHoveredInto(props.value - 1)}>
        {_arr.map((key, index) => {
            return <RateStar
                key={index}
                onMouseEnter={() => setHoveredInto(index)}
                active={index <= hoveredInto}
                onClick={() => props.onChange && props.onChange(index + 1)}
            />
        })}
    </RateContainer>
}

export {FeedbackRate}

export default FeedbackRate