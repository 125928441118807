import React, {memo} from 'react';
import {IconProps} from "./IconLogotype";

const IconChevDown: React.FunctionComponent<IconProps> = ({width= 24, color= "currentColor"}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24"
                fill={color}>
        <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
    </svg>
};

export default memo(IconChevDown);