import React, {memo} from 'react';
import {IconProps} from "./IconLogotype";

const IconChevUp: React.FunctionComponent<IconProps> = ({width= 24, color= "currentColor"}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24"
                fill={color}>
        <path d="m6.293 13.293 1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586z"></path>
    </svg>
};

export default memo(IconChevUp)