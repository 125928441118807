import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import "./styles/global.css"
import {TelegramWebApp} from "./components";
import {AuthContext} from "./contexts";
import "moment/locale/ru"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

async function validateHash() {
    return true
}

root.render(<TelegramWebApp validateHash={validateHash}><AuthContext><App /></AuthContext></TelegramWebApp>)