import styled from "@emotion/styled";

const FeedbackFormItemTitle = styled.td`
  padding: var(--space-gap-2x) var(--space-gap);
`

export {
    FeedbackFormItemTitle
}

export default FeedbackFormItemTitle