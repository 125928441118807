import axios, {AxiosInstance} from "axios";
import {BaseResponseInterface} from "../responses/BaseResponseInterface";
import {UserResponse} from "../responses/UserResponse";
import EmployeePage from "../Business/EmployeePage";
import {MainPage} from "../Business";
import {BotEnums} from "../types";
import { BotTransactionInterface, ExternalUserInterface, UserInterface } from "../interfaces";
import { TransactionResponse } from "./freshbackApi";

class BotApi {

    $api: AxiosInstance

    get bot_type () {
        return window.location.pathname === "/employee" ? BotEnums.EMPLOYEE : BotEnums.TENANT
    }


    checkRights (bot_type: BotEnums, roles: number[]) {
        return (
            roles.includes(2048)
            || roles.includes(4096)
            || (bot_type === BotEnums.TENANT && roles.includes(4))
            || (bot_type === BotEnums.EMPLOYEE && roles.includes(16))
        )
    }

    constructor() {
        this.$api = axios.create({
            baseURL: process.env.REACT_APP_BOT_API_URL
        })

        this.$api.interceptors.request.use((config) => {
            const Telegram = (window as any).Telegram

            if (config && config.headers && Telegram && Telegram.WebApp.initDataUnsafe && Telegram.WebApp.initDataUnsafe.user && Telegram.WebApp.initDataUnsafe.user.id) {
                // config.headers.Authorization = `${this.bot_type} ${Telegram.WebApp.initDataUnsafe.user.id}`
                // @ts-ignore
                config.headers.Authorization = `${this.bot_type} ` + window?.Telegram?.WebApp?.initData
            }
            return config
        })
    }

    async setOnPage(pageId: string, dataInfo?: string) {
        const {data} =  await this.$api.patch<UserInterface>("/api/v1/user/page", {
            pageId: pageId,
            data: dataInfo
        })

        return data
    }

    async sendSmsCode(payload: { marketingCampaignId: number, profileId: number, cashbackTerm: number, paymentTypeId: number, phone?: string, salesUnitId: number, transactionAmount: string }) {
        const {data} = await this.$api.post< BaseResponseInterface<{ items: number[] } | { message_id: number, telegram_id: number } > >(`/api/v1/user/transaction`, payload)
        return data
    }


    async getTransaction(message_id: number, telegram_id: number) {
        const {data} = await this.$api.get< BaseResponseInterface<BotTransactionInterface>>(`/api/v1/user/transaction?telegram_id=${telegram_id}&message_id=${message_id}`)
        return data
    }

    async getMe () {
        try {
            const { data } = await this.$api.get<BaseResponseInterface<UserResponse>>("/api/v1/user")

            if (data._embedded) {
                return data._embedded
            }
        } catch (e) {
            console.log(e)
        }


        if (localStorage.getItem("refreshToken" + this.bot_type)) {
            const apiUrl = process.env.REACT_APP_FRESHBACK_API_URL

            try {
                const user: ExternalUserInterface = (await axios.post(apiUrl + "/api/authentication/refresh", {
                    refreshToken: localStorage.getItem("refreshToken" + this.bot_type),
                    accessToken: localStorage.getItem("accessToken" + this.bot_type),
                    fingerprint: navigator.userAgent,
                })).data

                localStorage.setItem("refreshToken" + this.bot_type, user.refreshToken)
                localStorage.setItem("accessToken" + this.bot_type, user.accessToken)

                return {
                    user: {
                        telegram_id: 1,
                        phone: user.login,
                        bot_type: this.bot_type
                    },
                    tokens: {
                        accessToken: user.accessToken,
                        refreshToken: user.refreshToken
                    },
                    external: user
                }
            } catch (e) {
                localStorage.removeItem("refreshToken" + this.bot_type)
                localStorage.removeItem("accessToken" + this.bot_type)
            }
        }

        throw new Error("")

    }

}

const api = new BotApi()

export { api as BotApi }

export default api
