import styled from "@emotion/styled";
import React, {useLayoutEffect, useRef} from "react";

const StyledTextArea = styled.textarea`
  background-color: var(--secondary-bg-color);
  padding: 8px 14px;
  height: 36px;
  border: 0;
  border-radius: var(--border-radius-large);
  color: inherit;
  outline: none;
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
`

const MIN_TEXTAREA_HEIGHT = 34;

const TextArea: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = (props) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)


    useLayoutEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "inherit"
            textareaRef.current.style.height = `${Math.max(
                textareaRef.current.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`
        }
    }, [props.value]);

    return <StyledTextArea ref={textareaRef} {...props} />
}

export {TextArea}

export default TextArea