import styled from "@emotion/styled";


import React from 'react';
import {css} from "@emotion/react";

export const BottomNavigatorItem = styled.div< { isActive?: boolean, badgeCount?: number,  label?: string,} >`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  
  img {
    width: 100%;
    height: 100%;
  }
  
  svg {
    width: 27px;
    height: 27px;
    transition: all 150ms ease-in-out;
    fill: ${props => props.isActive ? "var(--text-color)" : "var(--hint-color)"};
    ${({label}) => label && css`
      margin-bottom: 14px;
    `}
    
    &#MainButtonIcon {
      width: 40px;
      height: 40px;
      transform: scale(${props => props.isActive ? "1.2" : "1"});
      filter: grayscale(${props => props.isActive ? "0" : "0.2"});
      
    }
  }


  &::after {
    content: "${props => props.badgeCount}";
    position: absolute;
    right: 0;
    top: 5px;
    background-color: var(--logo-freshback-color);
    padding: 1px 6px;
    min-width: 21px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 1000px;
    display: ${props => !props.badgeCount ? "none" : "inline-flex"};
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: var(--button-text-color);
  }
  &::before {
    content: "${({label}) => label}";
    position: absolute;
    bottom: 7px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    color: ${props => props.isActive ? "var(--text-color)" : "var(--hint-color)"};
  }
  
  
`