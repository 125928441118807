import React, {CSSProperties, ReactElement} from 'react';
import {AppMessageProps} from "./types";
import styled from "@emotion/styled";


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  min-height: 250px;
  
`
const Icon = styled.div`
  
  & svg {
    height: 60px;
    width: 60px;
    color: var(--link-color);
  }
`
const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--hint-color);
`
export const AppMessage: React.FunctionComponent<AppMessageProps & { style?: CSSProperties }> = ({
                                                                                                     icon,
                                                                                                     text,
                                                                                                     style
                                                                                                 }) => {
    return <Container style={style}>
        {icon && <Icon>{icon}</Icon>}
        <Text>{text}</Text>
    </Container>
};

