import React, {memo} from 'react';
import {IconProps} from "./IconLogotype";

const IconRub: React.FunctionComponent<IconProps> = ({width = 24, color = "currentColor"}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={width} height={width}
                viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
            <path
                d="M2380 4794 c-196 -18 -455 -78 -628 -145 -673 -264 -1188 -845 -1361 -1535 -53 -211 -65 -318 -65 -554 0 -236 12 -343 65 -554 195 -778 820 -1407 1599 -1610 221 -57 327 -70 570 -70 236 0 343 12 554 65 779 195 1407 819 1610 1599 57 221 70 327 70 570 0 243 -13 349 -70 570 -223 858 -945 1507 -1829 1645 -97 15 -425 27 -515 19z m735 -1217 c318 -93 501 -358 482 -697 -8 -127 -24 -189 -77 -298 -57 -114 -149 -206 -262 -261 -146 -72 -164 -74 -530 -79 l-328 -3 0 -120 0 -119 200 0 200 0 0 -160 0 -160 -200 0 -200 0 0 -160 0 -160 -200 0 -200 0 0 160 0 160 -120 0 -120 0 0 160 0 160 120 0 120 0 0 120 0 120 -120 0 -120 0 0 160 0 160 120 0 120 0 0 520 0 521 528 -3 c466 -3 534 -6 587 -21z"/>
            <path
                d="M2320 2920 l0 -360 333 0 c275 0 343 3 387 16 101 30 162 83 209 184 23 48 26 67 26 160 -1 92 -4 112 -27 161 -36 79 -87 131 -160 165 l-63 29 -352 3 -353 3 0 -361z"/>
        </g>
    </svg>
};

export default memo(IconRub)