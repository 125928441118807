import {RIPPLE_CLASS, RIPPLE_MASK_CLASS} from "../data/constant"

export function initRipple(rippleHandler: HTMLElement) {

    const redraw = function (el: HTMLElement) {
        return el.offsetTop + 1
    }

    const isTouch = ('ontouchstart' in window)

    function onRippleStart(e: TouchEvent | MouseEvent) {
        const rippleMask = rippleHandler.querySelector("." + RIPPLE_MASK_CLASS) as HTMLSpanElement

        const rect = rippleMask.getBoundingClientRect()

        let clientX = 0
        let clientY = 0

        if (e.type === 'touchstart' && 'targetTouches' in e) {
            clientX = e.targetTouches[0].clientX
            clientY = e.targetTouches[0].clientY
        } else if ('clientX' in e) {
            clientX = e.clientX
            clientY = e.clientY
        }

        const rippleX = (clientX - rect.left) - rippleMask.offsetWidth / 2
        const rippleY = (clientY - rect.top) - rippleMask.offsetHeight / 2
        const ripple = rippleHandler.querySelector('.' + RIPPLE_CLASS) as HTMLSpanElement

        ripple.style.transition = 'none'
        redraw(ripple)
        ripple.style.transform = 'translate3d(' + rippleX + 'px, ' + rippleY + 'px, 0) scale3d(0.2, 0.2, 1)'
        ripple.style.opacity = "1"
        redraw(ripple)

        ripple.style.transform = 'translate3d(' + rippleX + 'px, ' + rippleY + 'px, 0) scale3d(1, 1, 1)'
        ripple.style.transition = ''

        function onRippleEnd() {
            ripple.style.transitionDuration = 'var(--ripple-end-duration, .2s)'
            ripple.style.opacity = "0"

            if (isTouch) {
                document.removeEventListener('touchend', onRippleEnd)
                document.removeEventListener('touchcancel', onRippleEnd)
            } else {
                document.removeEventListener('mouseup', onRippleEnd)
            }
        }

        if (isTouch) {
            document.addEventListener('touchend', onRippleEnd)
            document.addEventListener('touchcancel', onRippleEnd)
        } else {
            document.addEventListener('mouseup', onRippleEnd)
        }
    }

    if (!rippleHandler.querySelector("." + RIPPLE_MASK_CLASS)) {
        const rippleMask = document.createElement("span")
        rippleMask.classList.add(RIPPLE_MASK_CLASS)

        const ripple = document.createElement("span")
        ripple.classList.add(RIPPLE_CLASS)
        rippleMask.appendChild(rippleMask)
        rippleHandler.appendChild(rippleMask)
    }

    if (isTouch) {
        rippleHandler.removeEventListener('touchstart', onRippleStart)
        rippleHandler.addEventListener('touchstart', onRippleStart)
    } else {
        rippleHandler.removeEventListener('mousedown', onRippleStart)
        rippleHandler.addEventListener('mousedown', onRippleStart)
    }
}