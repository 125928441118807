import React, {useCallback, useEffect, useMemo, useState} from "react";
import Selector from "../../../components/Selector";
import {useSalesUnits} from "../../stores/useSalesUnits";
import Input from "../../../components/Input";
import styled from "@emotion/styled";
import IconLogotype from "../../../icons/IconLogotype";
import PhoneInput from "../../../components/PhoneInput";
import {formatNumber, getNormalizedPhone, parseNiceFloat} from "../../../utils";
import { Button, Modal, ModalMessage, useTelegramWebApp } from "../../../components";
import {PAYMENT_TYPE} from "../../../data/constant";
import {useFreshbackApi} from "../../stores/useFreshbackApi";
import {useDebounce} from "../../../hooks";
import {SelectorItem} from "../../../components/Selector/types";
import {ModalSizes} from "../../../components/Modal/types";
import IconCheck from "../../../icons/IconCheck";
import IconError from "../../../icons/IconError";
import {MarketingCampaignsResponse} from "../../../http";
import phoneInput from "../../../components/PhoneInput";
import {SaleRegistration} from "../../../http/freshbackApi/requests";
import IconQr from "../../../icons/IconQr";
import env from "../../../data/env";
import { ConfirmationModal } from "../components/ConfirmationModal";
import axios from "axios";
import moment from "moment";


const ContainerAddTransaction = styled.div`
  flex: 1;
  padding: var(--space-gap-5x) 10px;
  margin: 0 -10px;
  background: var(--bg-color);
  z-index: 3;
  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
`

const Header = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: var(--hint-color);
  text-transform: uppercase;
  letter-spacing: 0.02rem;
`

const BlockAddTransaction = styled.div`
  margin: var(--space-gap-4x) 0 var(--space-gap-4x)
`

const BlockAddTransactionWrapper = styled.div`
  margin: var(--space-gap-4x) 0 var(--space-gap-5x) 0;
  flex: 1;
`

const BalanceCard = styled.div`

`
const BalanceCardTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`
const BalanceCardValue = styled.div`
  font-size: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: var(--space-gap-2x);
  margin-top: calc(var(--space-gap-2x) + 2px)
`
const BlockGroup = styled.div`
  display: flex;
  gap: var(--space-gap-4x);
  justify-content: space-between;
`

const SmsCodeWrapper = styled.div`
  color: var(--text-color);
  width: 90vw;
  border-radius: var(--border-radius-normal);
  padding: var(--space-gap-3x);
  background: var(--bg-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const AddTransactionPage: React.FC = () => {
    const api = useFreshbackApi(selector => selector.api)

    const {salesUnits} = useSalesUnits()
    const [shopId, setShopId] = useState(0)
    const [userId, setUserId] = useState<number>(0)
    const [userPhone, setUserPhone] = useState("")
    const [userBalance, setUserBalance] = useState<number>(0)
    const [paymentType, setPaymentType] = useState<number>(0)
    const [marketingCampaignId, setMarketingCampaignId] = useState<number>(0)

    const [name, setName] = useState("")
    const [birthDay, setBirthDay] = useState("")
    const [sex, setSex] = useState("")

    const [marketingCampaigns, setMarketingCampaigns] = useState<MarketingCampaignsResponse[]>([])

    const [sum, setSum] = useState<string>("")
    const [cashBackPercent, setCashBackPercent] = useState<{ value: string, computed: boolean }>({
        value: "",
        computed: false
    })

    const [cashBackSum, setCashBackSum] = useState<number>(0)
    const [paymentTypes, setPaymentTypes] = useState<SelectorItem<number>[]>(PAYMENT_TYPE)

    const [successModal, setSuccessModal] = useState(false)
    const [errorModal, setErrorModal] = useState(false)

    const [smsCode, setSmsCode] = useState("")
    const [smsCodeModalOpened, setSmsCodeModalOpened] = useState<string|false>(false)

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [tags, setTags] = useState<Array<{id: number, name: string}>>([])

    const webapp = useTelegramWebApp()


    const [botMessageResponse, setBotMessageResponse] = useState<{ message_id: number, telegram_id: number } | undefined>()

    const isValid = useMemo(() => {
        // if (paymentType === 4 && userPhone.length === 0 && userId) {
        //     return false;
        // }
        if (paymentType === 4 && !userId) {
            return false;
        }
        if (paymentType === 4 && userBalance <= 0) {
            return false
        }

        return !loading &&
            (userId === 0 ? (name.length > 0 && sex.length > 0) : true)
            && shopId > 0
            && parseNiceFloat(sum, false) > 0
            && paymentType > 0
            && parseNiceFloat(cashBackPercent.value, false) > 0
    }, [loading, shopId, sum, paymentType, cashBackPercent, userBalance, userId, birthDay, name, sex])

    useEffect(() => {
        api.getPaymentTypes().then((response) => {
            setPaymentTypes(response.map(item => ({id: item.id, option: item.name})))
        })
    }, [])

    useEffect(() => {

        setCashBackSum((parseNiceFloat(sum, false) as number) * (parseNiceFloat(cashBackPercent.value, false) as number) / 100)

    }, [sum, cashBackPercent])

    useEffect(() => {
        if (userId) {
            api.getEmplBalance(userId).then((balance) => {
                setUserBalance(balance.currentBalance)
            }).catch(() => {
                setUserBalance(0)
            })
        } else {
            setUserBalance(0)
        }
    }, [userId])

    useDebounce(() => {
        const phone = getNormalizedPhone(userPhone)
        if (phone.length === 11) {
            api.getUserByPhone(phone)
                .then((user) => {
                    setTags(user.tags ?? [])
                    setUserId(user.id ?? 0)
                    setName(user.firstName ?? "")
                    setBirthDay(user.birthday ? moment(user.birthday * 1000).format("YYYY-MM-DD") : "")
                    setSex(user.gender ?? "")
                })
                .catch(() => {
                    setUserId(0)
                    setName("")
                    setBirthDay("")
                    setSex("")
                    setTags([])
                })
        } else {
            setUserId(0)
            setName("")
            setBirthDay("")
            setSex("")
            setTags([])
        }
    }, 400, [userPhone])

    useEffect(() => {
        if (salesUnits.length === 1) {
            setShopId(salesUnits[0].id)
        }
    }, [salesUnits])

    useEffect(() => {
        setMarketingCampaigns([])
        if (shopId) {
            api.getMarketingCampaigns({
                salesUnitId: shopId
            }).then((response) => {
                setMarketingCampaigns(response)
            })
        }
    }, [shopId])

    useEffect(() => {
        api.getMarketingCampaignTerms({
            marketingCampaignId: marketingCampaignId,
            paymentTypeId: paymentType
        }).then((terms) => {
            if (terms.length > 0) {
                setCashBackPercent({
                    value: (terms.sort((a, b) => a.cashbackTerm - b.cashbackTerm)[terms.length - 1].cashbackTerm / 2).toString(),
                    computed: true
                })
            } else {
                setCashBackPercent(prev => ({
                    value: "",
                    computed: false
                }))
            }
        }).catch(() => {
            setCashBackPercent(prev => ({
                value: "",
                computed: false
            }))
        })
    }, [marketingCampaignId, paymentType])

    useEffect(() => {
        if (marketingCampaigns.length === 1) {
            setMarketingCampaignId(marketingCampaigns[0].id)
        }
    }, [marketingCampaigns])

    const doSaleRegistration = (code?: string) => {
        if (!isValid) return false;
        setLoading(true)
        const payload: SaleRegistration = {
            profileId: userId || 0,
            transactionAmount: sum,
            paymentTypeId: paymentType,
            phone: userPhone.length > 0 ? getNormalizedPhone(userPhone) : undefined,
            cashbackTerm: parseFloat(!cashBackPercent.computed ? cashBackPercent.value : ((parseFloat(cashBackPercent.value) * 2).toString())),
            salesUnitId: shopId,
            marketingCampaignId: marketingCampaignId
        }

        if (code) {
            payload.code = code
            //@ts-ignore
            payload.token = smsCodeModalOpened
            // payload.checkPoints = sum
        }

        if (!userId || userId === 0) {
            payload.customerName = name
            payload.customerBirthday = moment(birthDay, "YYYY-MM-DD").unix()
            payload.customerGender = sex
        }


        api.saleRegistration(payload).then(response => {
            setShopId(0)
            setUserId(0)
            setTags([])
            setName("")
            setBirthDay("")
            setSex("")

            setUserPhone("")
            setUserBalance(0)

            setCashBackSum(0)
            setSum("")
            setPaymentType(0)

            setCashBackPercent({
                value: "",
                computed: false
            })

            setSuccessModal(true)

            setSmsCode("")

            setTimeout(() => {
                window.location.reload()
            }, 1000)

        }).catch(e => {
            console.log(e)
            if (axios.isAxiosError(e) && e.response)  {
                setError(JSON.stringify(e.response.data))
            } else {
                setError("Неизвестная ошибка")
            }
            setErrorModal(true)
        }).finally(() => setLoading(false))
    }

    const onSubmit = useCallback(async () => {
        setError(undefined)
        if (paymentType === 4) {
            setLoading(true)

            try {
                const smsResponse = await api.sendSmsCode({
                    profileId: userId || 0,
                    // points: sum,
                    marketingCampaignId: marketingCampaignId,
                    paymentTypeId: paymentType,
                    cashbackTerm: !cashBackPercent.computed ? parseFloat(cashBackPercent.value) : ((parseFloat(cashBackPercent.value) * 2)),
                    phone: userPhone.length > 0 ? getNormalizedPhone(userPhone) : undefined,
                    salesUnitId: shopId,
                    transactionAmount: sum,
                })

                if (smsResponse && smsResponse._embedded && typeof smsResponse._embedded === "string") {
                    setSmsCodeModalOpened(smsResponse._embedded)
                } else if (smsResponse && smsResponse._embedded && 'telegram_id' in smsResponse._embedded && 'message_id' in smsResponse._embedded) {
                    setBotMessageResponse(smsResponse._embedded)
                }

            } catch (e) {
                console.log(e)
                if (axios.isAxiosError(e) && e.response)  {
                    setError(JSON.stringify(e.response.data))
                } else {
                    setError("Неизвестная ошибка")
                }
            } finally {
                setLoading(false)
            }


        } else {
            doSaleRegistration()
        }

    }, [isValid, cashBackPercent, marketingCampaignId, paymentType, userId, sum, shopId, userPhone])

    const onSubmitSmsCodeModal = useCallback(() => {

        doSaleRegistration(smsCode)

    }, [smsCode])

    const bdayMoment = moment(birthDay)
    const daysBeforeDr = bdayMoment.isValid() ? moment().startOf("day").diff(bdayMoment.set("year", moment().get("year")).startOf("day"), "days") : undefined


    return <ContainerAddTransaction>
        { botMessageResponse && (
          <ConfirmationModal
            telegram_id={botMessageResponse.telegram_id}
            message_id={botMessageResponse.message_id}
            onClose={() => {
                setBotMessageResponse(undefined)

                setShopId(0)
                setUserId(0)
                setTags([])
                setName("")
                setBirthDay("")
                setSex("")

                setUserPhone("")
                setUserBalance(0)

                setCashBackSum(0)
                setSum("")
                setPaymentType(0)

                setCashBackPercent({
                    value: "",
                    computed: false
                })

                setSmsCode("")

                window.location.reload()

            }}
          />
        ) }
        <Modal
            opened={successModal}
            hideEveryClick={true}
            onHide={() => setSuccessModal(false)}>
            <ModalMessage Icon={IconCheck} iconColor={"var(--text-success)"} message={"Транзакция создана"}/>
        </Modal>
        <Modal
            opened={errorModal}
            hideEveryClick={true}
            onHide={() => setErrorModal(false)}>
            <ModalMessage Icon={IconError} iconColor={"var(--text-error)"} message={"Транзакция не создана"}/>
        </Modal>
        <Modal
            opened={!!smsCodeModalOpened}
            onHide={() => {
                setSmsCode("");
                setSmsCodeModalOpened(false);
            }}>
            <SmsCodeWrapper>
                <div>Клиенту отправлено смс с кодом, подтвердите списание, введя в поле код и нажмите Ок.</div>
                <Input
                    label={"Код"}
                    placeholder={"..."}
                    type={"text"}
                    value={smsCode}
                    onChange={(e) => setSmsCode(e.target.value.trimStart())}
                />
                <Button disabled={smsCode.trim().length == 0} onClick={onSubmitSmsCodeModal}>Ок</Button>
            </SmsCodeWrapper>
        </Modal>
        <Selector
            header={"Выберите торговую точку"}
            selected={shopId}
            label={"Торговая точка"}
            onSelect={(e) => {
                setShopId(e.item.id)
            }}
            items={salesUnits.map(item => ({
                id: item.id,
                option: item.name,
                data: item
            }))}
        />
        <BlockAddTransaction>
            <Header>
                <div style={{display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between", alignItems: "center"}}>
                    <span>Клиент</span>
                    <Button
                      style={{display: "inline-flex", width: "auto"}}
                      onClick={() => {
                          // @ts-ignore
                          webapp.showScanQrPopup({
                              text: "Отсканируйте Qr-код покупателя"
                          }, function (text: string) {

                              const textArr = text.split(env.qrCodeUrl)
                              if (textArr.length === 2) {
                                  setUserPhone("")
                                  const id = textArr[1]
                                  // @ts-ignore
                                  webapp.showAlert("Qr-код покупателя принят")
                                  setUserId(parseInt(id))
                                  return true;
                              }

                              return false;
                          })
                      }}
                    >
                        <IconQr />
                    </Button>
                </div>
            </Header>
            <BlockGroup>
                <BlockAddTransactionWrapper>
                    <PhoneInput
                        label={"Телефон"}
                        placeholder={"+7 999 999 99 99"}
                        value={userPhone}
                        onChange={(e) => {
                            // setUserId(0)
                            setUserPhone(e.target.value.trimStart())
                        }}
                    />
                </BlockAddTransactionWrapper>
                <BlockAddTransactionWrapper>
                    <BalanceCard>
                        <BalanceCardTitle>ID {userId ? (`№${userId}`) : ""}</BalanceCardTitle>
                        <BalanceCardValue><IconLogotype width={20} color={"#fff"} fill={"var(--logo-freshback-color)"}/><span
                          style={{color: userBalance < 0 ? "var(--text-error)" : "var(--text-success)"}}>{formatNumber(userBalance)}</span></BalanceCardValue>
                    </BalanceCard>
                </BlockAddTransactionWrapper>
            </BlockGroup>
            <BlockGroup>
                <BlockAddTransactionWrapper style={{marginTop: 0}}>
                    <Input
                      label={"Имя *"}
                      placeholder={"Имя"}
                      value={name}
                      disabled={userId > 0}
                      onChange={(e) => {
                          if (!userId || userId === 0) {
                            setName(e.target.value.trimStart())
                          }
                      }}
                    />
                </BlockAddTransactionWrapper>
                <BlockAddTransactionWrapper style={{marginTop: 0}}>
                    <Input
                      label={"Дата рождения"}
                      placeholder={"Дата рождения"}
                      type={"date"}
                      value={birthDay}
                      disabled={userId > 0}
                      onChange={(e) => {
                          if (!userId || userId === 0) {
                              setBirthDay(e.target.value)
                          }
                      }}
                      style={{border: `1px solid ${daysBeforeDr === -1 ? "yellow" : (daysBeforeDr === 0 ? "red" : (daysBeforeDr === 1 ? "#ff5e00" : "transparent"))}`}}
                    />
                </BlockAddTransactionWrapper>
            </BlockGroup>

            {(userId && tags.length > 0) ? (
              <div>
                  { tags.map(tag => (
                    <div style={{
                        display: "inline-block",
                        padding: "2px 8px",
                        border:" 1px solid var(--button-color)",
                        borderRadius: "5px",
                        color: "var(--text-color)",
                        marginRight: "6px",
                        marginBottom: "6px",
                    }} key={tag.id}>{ tag.name }</div>
                  )) }
              </div>
            ) : ""}

            {(!userId || userId === 0) && (
              <BlockGroup>
                  <BlockAddTransactionWrapper style={{marginTop: 0}}>
                      <Button onClick={() => setSex("мужской")} style={{fontSize: 10, filter: `brightness(${sex === "мужской" ? "1.4" : "1"})`}}>Муж</Button>
                  </BlockAddTransactionWrapper>
                  <BlockAddTransactionWrapper style={{marginTop: 0}}>
                      <Button onClick={() => setSex("женский")} style={{fontSize: 10, filter: `brightness(${sex === "женский" ? "1.4" : "1"})`}}>Жен</Button>
                  </BlockAddTransactionWrapper>
              </BlockGroup>
            )}
        </BlockAddTransaction>

        <BlockAddTransaction>
            <Header>Продажа</Header>
            <BlockGroup>
                <BlockAddTransactionWrapper style={{flex: "unset", width: "50%"}}>
                    <Input
                        label={"Сумма"}
                        placeholder={"..."}
                        type={"number"}
                        value={sum}
                        onChange={(e) => setSum(e.target.value)}
                    />
                </BlockAddTransactionWrapper>

                <BlockGroup style={{flex: "unset", width: "50%"}}>
                    <BlockAddTransactionWrapper>
                        <div>
                            <Input
                                label={"Кешбэк %"}
                                placeholder={"0%"}
                                type={"number"}
                                value={cashBackPercent.value}
                                readOnly={true}
                                onChange={(e) => setCashBackPercent({
                                    value: e.target.value,
                                    computed: false
                                })}
                            />
                        </div>
                    </BlockAddTransactionWrapper>
                    <BlockAddTransactionWrapper>
                        <div>
                            <Input
                                label={"Кешбэк"}
                                placeholder={"0"}
                                value={cashBackSum}
                                readOnly={true}
                            />
                        </div>
                    </BlockAddTransactionWrapper>
                </BlockGroup>

            </BlockGroup>
            <BlockGroup>
                {paymentTypes.map(type => {
                    return <Button
                        style={{fontSize: 10, filter: `brightness(${type.id === paymentType ? "1.4" : "1"})`}}
                        onClick={() => {

                            if (type.id === 4) {
                                // if (userPhone.length === 0 && userId) {
                                //     // @ts-ignore
                                //     webapp.showAlert("Введите номер телефона")
                                // }
                                if (!userId) {
                                    // @ts-ignore
                                    webapp.showAlert("Введите номер телефона")
                                }
                            }

                            setPaymentType(type.id)

                        }}>{type.option}</Button>
                })}
                {/*<BlockAddTransactionWrapper>*/}
                {/*    <Selector*/}
                {/*        selected={paymentType}*/}
                {/*        header={"Выберите тип оплаты"}*/}
                {/*        label={"Тип оплаты"}*/}
                {/*        onSelect={(e) => {*/}
                {/*            setPaymentType(e.item.id)*/}
                {/*        }}*/}
                {/*        items={paymentTypes}*/}
                {/*    />*/}
                {/*</BlockAddTransactionWrapper>*/}
            </BlockGroup>
            <BlockGroup>
                <BlockAddTransactionWrapper>
                    <Selector
                        selected={marketingCampaignId}
                        header={"Выберите акцию"}
                        label={"Акция"}
                        onSelect={(e) => {
                            setMarketingCampaignId(e.item.id)
                        }}
                        items={marketingCampaigns.map(item => ({id: item.id, option: item.name}))}
                    />
                </BlockAddTransactionWrapper>
            </BlockGroup>
            <Button
                onClick={onSubmit}
                disabled={!isValid}>
                Добавить транзакцию
            </Button>
            <BlockGroup style={{marginTop: 10}}>
                {error}
            </BlockGroup>
        </BlockAddTransaction>

    </ContainerAddTransaction>
}

export {AddTransactionPage}

export default AddTransactionPage
