import React, {HTMLAttributes, memo} from 'react';
export interface IconProps {
    color?: string,
    width?: string|number
}

const IconLogotype: React.FunctionComponent<IconProps & {fill?: string} & HTMLAttributes<SVGSVGElement>> = ({width = "100%", color= "#000", fill="currentColor", id, ...props}) => {

    return <>
        <svg width={width} height={width} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" id={id} {...props}>
            <path
                d="M37.2287 19C37.2287 29.4926 28.894 38 18.6144 38C8.33276 38 0 29.4926 0 19C0 8.50539 8.33473 3.5585e-07 18.6144 3.5585e-07C28.894 -0.00200895 37.2287 8.50539 37.2287 19Z"
                fill={fill} id={"inner"}/>
            <path

                d="M15.6284 19.6122C14.9532 19.6302 14.2819 19.6182 13.6185 19.4333C11.7779 18.919 10.5358 17.1226 10.5496 15.035C10.5633 12.9694 11.7622 11.4001 13.711 10.946C14.272 10.8134 14.839 10.8034 15.4079 10.8134H17.1205V19.6102H15.6284V19.6122ZM20.1068 10.8154H21.8174C22.3863 10.8034 22.9532 10.8154 23.5143 10.948C25.4631 11.4021 26.6639 12.9714 26.6757 15.037C26.6895 17.1226 25.4474 18.921 23.6068 19.4353C22.9434 19.6202 22.2702 19.6303 21.5969 19.6142H20.1048V10.8154H20.1068ZM22.0221 21.8304C22.6245 21.8324 23.2288 21.8184 23.8312 21.742C29.2781 21.0468 30.8293 15.5272 28.8864 11.7658C27.6541 9.37877 25.5222 8.43238 22.9828 8.36005C22.1678 8.33795 21.0221 8.33594 20.1087 8.33795V8.33594H17.1225V8.33795C16.2071 8.33594 15.0634 8.33594 14.2484 8.36005C11.709 8.43238 9.57712 9.37877 8.34482 11.7658C6.40189 15.5272 7.95309 21.0448 13.4 21.742C14.0024 21.8184 14.6047 21.8324 15.2091 21.8304H17.1244V23.5142H9.51609V23.9563V25.5999V26.0419H17.1244V30.3499H20.1107V26.0419H27.719V25.5999V23.9563V23.5142H20.1107V21.8304H22.0221Z"
                fill={color}/>
        </svg>
    </>
};

export default memo(IconLogotype)
