import {SelectorItem} from "../components/Selector/types";

export const PAYMENT_TYPE: SelectorItem<number>[] = [
    {
        id: 1,
        option: "Картой"
    },
    {
        id: 2,
        option: "Наличными"
    },
    {
        id: 3,
        option: "Картой (P2P)"
    },
    {
        id: 4,
        option: "Баллы"
    },
    {
        id: 5,
        option: "Банковский счёт"
    }
]

export const TRANSACTIONS_STATUS_TYPE: { [key: string]: { en_name: string, ru_name: string } } = {
    "1": {
        en_name: "waiting",
        ru_name: "Ожидание"
    },
    "2": {
        en_name: "success",
        ru_name: "Завершено"
    },
    "3": {
        en_name: "canceled",
        ru_name: "Отмена"
    },
}

export const RIPPLE_MASK_CLASS = "ripple-mask"
export const RIPPLE_CLASS = "ripple"
