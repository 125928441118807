const REACT_APP_BOT_API_URL         = process.env.REACT_APP_BOT_API_URL
const REACT_APP_FRESHBACK_API_URL   = process.env.REACT_APP_FRESHBACK_API_URL
const REACT_APP_ONLINE_SHOPS_URL    = process.env.REACT_APP_ONLINE_SHOPS_URL ?? "REACT_APP_ONLINE_SHOPS_URL"
const REACT_APP_QR_URL              = REACT_APP_ONLINE_SHOPS_URL + "/registration?ref_mode=t&ref_id="
const REACT_APP_CAPTCHA_SITE_KEY    = process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ""
const REACT_APP_ONLINE_PAGE         = REACT_APP_ONLINE_SHOPS_URL.slice(0, -5) // TODO: СДЕЛАТЬ ENV'ом
console.log(REACT_APP_QR_URL)

export default {
    botApiUrl: REACT_APP_BOT_API_URL,
    freshBackApiUrl: REACT_APP_FRESHBACK_API_URL,
    qrCodeUrl: REACT_APP_QR_URL,
    onlineShopsUrl: REACT_APP_ONLINE_SHOPS_URL,
    captchaSiteKey: REACT_APP_CAPTCHA_SITE_KEY,
    userPage: REACT_APP_ONLINE_PAGE
}
