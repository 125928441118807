import {SelectorItem, SelectorProps} from "./types";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import IconChevDown from "../../icons/IconChevDown";
import IconChevUp from "../../icons/IconChevUp";
import {useOtherBlockClick} from "../../hooks";

const SelectorWrapper = styled.div`
  position: relative;
`

const SelectorInner = styled.div`
  position: relative;
  //z-index: 2;
`

const SearchingInput = styled.input`
  width: calc(100% - 21px);
  background: transparent;
  padding: 0;
  border-radius: 0;
  border: 0;
  color: inherit;
  outline: none;
  font-size: 15px;
`

const SelectedContainer = styled.div<{ active: boolean}>`
  background-color: var(--secondary-bg-color);
  padding: 8px 14px;
  border-radius: ${props => props.active ? "var(--border-radius-normal) var(--border-radius-normal) 0 0" : "var(--border-radius-large)"};
  height: 36px;
  max-height: 36px;
  font-size: 15px;
  position: relative;
  z-index: 1;
  display: flex;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: ${props => props.active ? "var(--border-radius-normal) var(--border-radius-normal) 0 0" : "var(--border-radius-large)"};
    z-index: -1;
    opacity: 0.3;
    border: 1px solid var(--hint-color);
  }
`

const SelectorContext = styled.div`
  position: absolute;
  width: 100%;
  padding: 4px 1px 10px 1px;
  background-color: var(--secondary-bg-color);
  border-radius: 0 0 var(--border-radius-normal) var(--border-radius-normal);
  overflow: auto;
  max-height: var(--selector-max-width, 170px);
  //box-shadow: inset 0 0 0 1px #324053;
  z-index: 1000;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0 0 var(--border-radius-normal) var(--border-radius-normal);
    z-index: -1;
    opacity: 0.3;
    border: 1px solid var(--hint-color);
  }
`

const SelectorContextItem = styled.div<{ active?: boolean, disabled?: boolean }>`
  position: relative;
  width: 100%;
  cursor: pointer;
  background-color: var(--secondary-bg-color);
  padding: 10px;
  color: ${({active}) => active ? "var(--link-color)" : "var(--text-color)"};
  ${({active}) => active && css`
    font-weight: 500;
  `};

  &:hover {
    color: var(--link-color);
  }
`

const SelectorContextOverlay = styled.div`
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background: transparent;
    left: 0;
    top: 0;
`
const IconChev = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SelectorHeader = styled.div`
  font-size: 14px;
  margin-bottom: var(--space-gap-2x);
`

const StrippedText = styled.span`
  width: calc(100% - 21px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const MAIN_SELECTOR_OPENED_CLASS = "selector-opened"

function Selector<T = string | number, N = any>(props: SelectorProps<T, N>) {
    const [filterText, setFilterText] = useState<string>("")
    const [isContextOpened, setIsContextOpened] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<SelectorItem<T, N> | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setSelectedItem(props.items.find(item => item.id === props.selected) ?? null)
    }, [props.selected, props.items])

    const filteredItems = useMemo(() => {
        return props.items.filter(item => item.option.toString().toLowerCase().includes(filterText.toLowerCase()))
    }, [filterText, props.items])

    const openContext = useCallback(() => {
        setIsContextOpened(true)
        // document.body.classList.add(MAIN_SELECTOR_OPENED_CLASS)
        // setTimeout(() => {
        //     if (inputRef.current) {
        //         inputRef.current.focus()
        //     }
        // }, 250)
    }, [])

    const closeContext = useCallback(() => {
        setIsContextOpened(false)
        setFilterText("")
        // document.body.classList.remove(MAIN_SELECTOR_OPENED_CLASS)
    }, [])

    useOtherBlockClick(() => {
        closeContext()
    }, containerRef, [closeContext])

    return <SelectorWrapper ref={containerRef}>
        {props.header && <SelectorHeader>{props.header}</SelectorHeader>}
        <SelectorInner>
            <SelectedContainer onClick={() => !isContextOpened && openContext()} active={isContextOpened}>
                {
                    isContextOpened ? <>
                        <SearchingInput
                            ref={inputRef}
                            placeholder={"Поиск..."}
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value.trimStart())}
                            // style={{display: isContextOpened ? "block" : "none"}}
                        />
                    </> : <>
                     <StrippedText
                         // style={{display: isContextOpened ? "none" : "block"}}
                     >
                        {selectedItem ? selectedItem.option : props.label}
                    </StrippedText>
                    </>
                }
                <IconChev onClick={() => setIsContextOpened(!isContextOpened)}>{isContextOpened ? <IconChevUp color={"var(--hint-color)"} /> : <IconChevDown color={"var(--hint-color)"}/>}</IconChev>
            </SelectedContainer>
            {isContextOpened && <>
                <SelectorContext>
                    {filteredItems.map(item => {
                        return <SelectorContextItem
                            key={item.id as any}
                            active={item.id === props.selected}
                            disabled={item.disabled}
                            onClick={() => {
                                props.onSelect({item: item})
                                closeContext()
                            }}>
                            {item.option}
                        </SelectorContextItem>
                    })}
                </SelectorContext>
            </>}
        </SelectorInner>
        {/*{isContextOpened && <SelectorContextOverlay onClick={closeContext}></SelectorContextOverlay>}*/}
    </SelectorWrapper>
}

export {
    Selector
}

export default Selector