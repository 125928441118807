import create from 'zustand'
import {devtools} from "zustand/middleware";
import {ExternalUserInterface, TokenInterface, UserInterface} from "../../interfaces";
import {BotEnums, Setter} from "../../types";


interface UserStoreInterface {
    user: UserInterface,
    externalUser: ExternalUserInterface,
    externalUserInfo: any,
    tokens: TokenInterface,
    loadingUser: boolean,
    error: boolean,
    setUser: (state: Setter<UserInterface>) => any,
    setExternalUser: (state: Setter<ExternalUserInterface>) => any,
    setExternalUserInfo: (state: Setter<any>) => any,
    setTokens: (state: Setter<TokenInterface>) => any,
    setLoading: (state: Setter<boolean>) => any,
    setError: (state: Setter<boolean>) => any,

}

export const useUser = create(devtools<UserStoreInterface>((set, get) => ({
    user: {
        telegram_id: 0,
        phone: "",
        bot_type: BotEnums.TENANT
    },
    externalUser: {
        "id": 0,
        "login": "",
        "accessToken": "",
        "refreshToken": "",
        "roles": [],
        "corporateSalesUnitIds": [],
        "tenantProfileId": 0,
        "fingerprint": "",
        "employeeSalesUnitIds": []
    },
    externalUserInfo: null,
    tokens: {
        accessToken: "",
        refreshToken: "",
        "fingerprint": "",
    },
    loadingUser: true,
    error: false,
    setUser: (state: Setter<UserInterface>) => {
        if (typeof state === "function") {
            set({
                user: state(get().user)
            })
        } else {
            set({
                user: state
            })
        }
    },
    setExternalUser: (state: Setter<ExternalUserInterface>) => {
        if (typeof state === "function") {
            set({
                externalUser: state(get().externalUser)
            })
        } else {
            set({
                externalUser: state
            })
        }
    },
    setExternalUserInfo: (state: Setter<any>) => {
        if (typeof state === "function") {
            set({
                externalUserInfo: state(get().externalUserInfo)
            })
        } else {
            set({
                externalUserInfo: state
            })
        }
    },
    setTokens: (state: Setter<TokenInterface>) => {
        if (typeof state === "function") {
            set({
                tokens: state(get().tokens)
            })
        } else {
            set({
                tokens: state
            })
        }
    },
    setLoading: (state: Setter<boolean>) => {
        if (typeof state === "function") {
            set({
                loadingUser: state(get().loadingUser)
            })
        } else {
            set({
                loadingUser: state
            })
        }
    },
    setError: (state: Setter<boolean>) => {
        if (typeof state === "function") {
            set({
                error: state(get().error)
            })
        } else {
            set({
                error: state
            })
        }
    }

})))
