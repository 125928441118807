import { useUser } from "../../stores/useUser";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { Button, Modal, ModalMessage, TextArea } from "../../../components";
import { Loader } from "../../../components/Loader";
import IconCheck from "../../../icons/IconCheck";
import IconError from "../../../icons/IconError";
import {
  FeedbackFormItem,
  FeedbackFormItemTitle,
  FeedbackFormItemValue, FeedbackRate,
  FeedbackWrapper
} from "../../../features/OrderFeedback/components";
import moment from "moment/moment";
import { parseNiceFloat } from "../../../utils";
import Input from "../../../components/Input";
import styled from "@emotion/styled";
import { useFreshbackApi } from "../../stores/useFreshbackApi";

const FormWrapper = styled.div`
    display: flex;
    gap: var(--space-gap-4x);
    flex-direction: column;
    color: var(--text-color);
    width: 90vw;
    border-radius: var(--border-radius-normal);
    padding: var(--space-gap-3x);
    background: var(--bg-color);
`

const FormItem = styled.div`
    display: flex;
    gap: var(--space-gap-4x);
    flex: 1;
`

const SexWrapper = styled.div`
    display: flex;
    gap: var(--space-gap-4x);
    justify-content: space-between;
    flex: 1;
`

export const UserModal = () => {
  const externalUserInfo = useUser((state) => state.externalUserInfo)
  const setExternalUserInfo = useUser((state) => state.setExternalUserInfo)
  const [closed, setClosed] = useState(false)
  const [name, setName] = useState("")
  const [birthDay, setBirthDay] = useState("")
  const [sex, setSex] = useState("")
  const {api} = useFreshbackApi()

  const onSubmit = () => {
    const r: any = {}

    if (name.length > 0) {
      r.firstName = name
    }

    if (sex.length > 0) {
      r.gender = sex
    }

    if (birthDay.length > 0) {
      r.birthday = moment(birthDay, "YYYY-MM-DD").unix()
    }

    api.request.put(`/api/tenant-profiles/${api.tenantProfileId}`, r)
    setClosed(true)
  }

  useLayoutEffect(() => {
      if (externalUserInfo) {
        setName(externalUserInfo.firstName ?? "")
        setBirthDay(externalUserInfo.birthday ? moment(externalUserInfo.birthday * 1000).format("YYYY-MM-DD") : "")
        setSex(externalUserInfo.gender ? externalUserInfo.gender.toLowerCase() : "")
      }
  }, [externalUserInfo]);

  if (!externalUserInfo || closed) {
    return <></>
  }

  if (
    externalUserInfo.firstName
    && externalUserInfo.firstName !== "Житель"
    && externalUserInfo.firstName !== "Имя пользователя"
    && externalUserInfo.birthday
    && externalUserInfo.gender
  ) {
    return <></>
  }

  return  <Modal opened={!closed} onHide={() => setClosed(true)}>
    <FormWrapper>
      <FormItem>
          Введите информацию о себе
      </FormItem>

      <FormItem>
        <Input
          label={"Имя"}
          placeholder={"Имя"}
          value={name}
          onChange={(e) => {
            setName(e.target.value.trimStart())
          }}
        />
      </FormItem>


      <FormItem>
        <Input
          label={"Дата рождения"}
          placeholder={"Дата рождения"}
          type={"date"}
          value={birthDay}
          onChange={(e) => {
            setBirthDay(e.target.value)
          }}
        />
      </FormItem>

      <FormItem>
        <SexWrapper>
          <Button onClick={() => setSex("мужской")} style={{fontSize: 10, filter: `brightness(${sex === "мужской" ? "1.4" : "1"})`}}>Муж</Button>
          <Button onClick={() => setSex("женский")} style={{fontSize: 10, filter: `brightness(${sex === "женский" ? "1.4" : "1"})`}}>Жен</Button>
        </SexWrapper>

      </FormItem>

      <FormItem>
        <Button onClick={onSubmit}>Сохранить</Button>
      </FormItem>
    </FormWrapper>
  </Modal>
}
