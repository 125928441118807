import React, {memo} from 'react';
import {IconProps} from "./IconLogotype";

const IconUserCircle: React.FunctionComponent<IconProps> = ({width = 24, color = "currentColor"}) => {
    return <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19H18ZM15 15V14V15ZM9 15V14V15ZM4 19C4 19.5523 4.44772 20 5 20C5.55228 20 6 19.5523 6 19H4ZM20 19C20 17.6739 19.4732 16.4021 18.5355 15.4645L17.1213 16.8787C17.6839 17.4413 18 18.2044 18 19H20ZM18.5355 15.4645C17.5979 14.5268 16.3261 14 15 14V16C15.7956 16 16.5587 16.3161 17.1213 16.8787L18.5355 15.4645ZM15 14H9V16H15V14ZM9 14C7.67392 14 6.40215 14.5268 5.46447 15.4645L6.87868 16.8787C7.44129 16.3161 8.20435 16 9 16V14ZM5.46447 15.4645C4.52678 16.4021 4 17.6739 4 19H6C6 18.2044 6.31607 17.4413 6.87868 16.8787L5.46447 15.4645Z"
            fill={color}/>
        <path
            d="M12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z"
            stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2"/>
    </svg>
};

export default memo(IconUserCircle)