import styled from "@emotion/styled";
import {css} from "@emotion/react";
import React from "react";
import {ButtonProps} from "./types";


export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--button-color);
  color: var(--button-text-color);
  text-decoration: none;
  width: 100%;
  padding: var(--space-gap) var(--space-gap-2x);
  outline: none;
  border: none;
  height: 36px;
  border-radius: var(--border-radius-small);
  gap: 5px;
  cursor: pointer;
  ${({disabled}) => disabled ? css`
    pointer-events: none;
    opacity: 0.5;
  ` : css`
    &:hover {
      filter: brightness(1.2);
    }
  `}
`

// export const Button: React.FC<ButtonProps & HTMLAttributes<HTMLButtonElement>> = ({children, ...props}) => {
//   const ref = useRef<HTMLButtonElement>(null)
//   useEffect(() => {
//     if (ref.current) {
//       initRipple(ref.current)
//     }
//   }, [ref])
//   return <StyledButton ref={ref} {...props}>
//     {children}
//     <span className={RIPPLE_MASK_CLASS}>
//       <span className={RIPPLE_CLASS} />
//     </span>
//   </StyledButton>
// }


export default Button