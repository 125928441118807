import React from 'react';
import {MainPage} from "./Business";
import EmployeePage from "./Business/EmployeePage";

const Component = window.location.pathname === "/employee" ? EmployeePage : MainPage

function App() {


  return (
    <Component />
  );
}

export default App;
