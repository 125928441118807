import IconError from "../../../../icons/IconError";
import React from "react";
import {ModalMessageProps} from "./types/ModalMessageProps";


const ModalMessage: React.FC<ModalMessageProps> = ({Icon, iconColor, message}) => {
    return <div style={{
        display: "flex",
        width: "100vw",
        flexDirection: "column",
        gap: "var(--space-gap-2x)",
        alignItems: "center",
        color: "var(--text-color)"
    }}>
        <Icon width={50} color={iconColor}/>
        <div style={{textAlign: "center"}}>
            {message}
        </div>
    </div>
}

export {ModalMessage}

export default ModalMessage