import {useFreshbackApi} from "../stores/useFreshbackApi";
import {
    AppMessage,
    BottomNavigator,
    FlexContainer,
    Header,
    SectionContainer,
    useTelegramWebApp
} from "../../components";
import {useUser} from "../stores/useUser";
import React, {useEffect, useMemo} from "react";
import {StickyContainer} from "../../components/StickyContainer";
import {BottomNavigatorItem} from "../../components/BottomNavigatorItem";
import IconLogotype from "../../icons/IconLogotype";
import IconAddUser from "../../icons/IconAddUser";
import IconNotification from "../../icons/IconNotification";
import IconBar from "../../icons/IconBar";
import {useActivePage} from "../stores/useActivePage";
import {AddTransactionPage} from "./pages";
import IconHistory from "../../icons/IconHistory";
import IconCodeCurly from "../../icons/IconCodeCurly";

const EmployeePage = () => {
    const api = useFreshbackApi((state) => state.api)

    const user = useUser((state) => state.user)

    const { activePageId, setActivePage, pages, setPages, ActiveComponent, setBadgeCount } = useActivePage()

    useEffect(() => {

        setPages({
            "UserAdd": {
                component: <AppMessage text={"Страница в разработке"} icon={<IconCodeCurly />}/>,
                icon: <IconAddUser />,
                // label: "Пользователь"
            },
            "NotificationPage": {
                component: <AppMessage text={"Страница в разработке"} icon={<IconCodeCurly />}/>,
                icon: <IconNotification />,
                // label: "Уведомления"
            },
            "MainPage": {
                component: <AddTransactionPage />,
                icon: <IconLogotype color={"#fff"} fill={"var(--logo-freshback-color)"} id={"MainButtonIcon"}/>
            },
            "TransactionPage": {
                component: <AppMessage text={"Страница в разработке"} icon={<IconCodeCurly />}/>,
                icon: <IconHistory />,
                // label: "Транзакции"
            },
            "SettingsPage": {
                component: <AppMessage text={"Страница в разработке"} icon={<IconCodeCurly />}/>,
                icon: <IconBar />,
                // label: "Настройки"
            },
        })

    }, [])


    return (
        <FlexContainer style={{paddingBottom: 60}}>
            <SectionContainer>
                <StickyContainer className={"user-container"}>
                    <Header phone={user.phone}/>
                </StickyContainer>
                {ActiveComponent()}
            </SectionContainer>
            <BottomNavigator>
                {
                    Object.keys(pages).map(page_id => {

                        return <BottomNavigatorItem key={page_id} label={pages[page_id].label} isActive={page_id === activePageId} badgeCount={pages[page_id].badgeCount} onClick={() => setActivePage(page_id)}>
                            {pages[page_id].icon}
                        </BottomNavigatorItem>
                    })
                }
            </BottomNavigator>
        </FlexContainer>
    );
}

export default EmployeePage