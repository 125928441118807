import {ExternalUserInterface, TokenInterface} from "../../interfaces";
import axios, {AxiosInstance} from "axios";
import {
    BalanceResponse,
    FullUserResponse,
    TransactionResponse,
    OrderResponse,
    PaymentTypeResponse,
    SalesUnitResponse,
    MarketingCampaignsResponse, OnlineShopResponse
} from "./responses";
import {TransactionProps} from "../../components/TransactionCard/types";
import {NotificationResponse} from "./responses/NotificationResponse";
import {NotificationCardProps} from "../../components/NotificationCard/types";
import { getMoment, getSortedByDate, SortableItemInterface, SortedByDateItemsInterface } from "../../utils";
import {TRANSACTIONS_STATUS_TYPE} from "../../data/constant";
import {MarketingCampaignsRequest, SaleRegistration} from "./requests";
import {BotApi} from "../botApi";
import {MarketingCampaignTermsRequest} from "./requests/MarketingCampaignTermsRequest";
import {MarketingCampaignTermsResponse} from "./responses/MarketingCampaignTermsResponse";


class FreshbackApi {
    private readonly $api: AxiosInstance

    private externalUser: ExternalUserInterface

    constructor(externalUser: ExternalUserInterface) {
        this.externalUser = externalUser

        this.$api = axios.create({
            baseURL: process.env.REACT_APP_FRESHBACK_API_URL
        })

        this.$api.interceptors.request.use((config) => {
            if (config && config.headers && this.externalUser.accessToken) {
                config.headers.Authorization = `Bearer ${this.externalUser.accessToken}`
            }
            return config
        })

        this.$api.interceptors.response.use((config) => {
            return config
        }, async (error) => {
            const originalRequest = error.config


            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true
                try {
                    const refreshTokens = await this.refreshToken()

                    this.externalUser.accessToken = refreshTokens.data.accessToken
                    this.externalUser.refreshToken = refreshTokens.data.refreshToken

                    if (localStorage.getItem("refreshToken" + BotApi.bot_type)) {
                        localStorage.setItem("refreshToken" + BotApi.bot_type, refreshTokens.data.refreshToken)
                    }

                    return this.$api.request(originalRequest)
                } catch (e) {
                    localStorage.removeItem("refreshToken")
                }
            }
            throw error
        })
    }

    get request() {
        return this.$api
    }

    refreshToken () {
        return axios.post<ExternalUserInterface>(process.env.REACT_APP_FRESHBACK_API_URL + "/api/authentication/refresh", {
            refreshToken: this.externalUser.refreshToken
        })
    }

    get tenantProfileId() {
        return this.externalUser.tenantProfileId
    }

    async getUserInfo(profile_id: number = this.externalUser.tenantProfileId!) {
        const {data} = await this.request.get<BalanceResponse>(`/api/tenant-profiles/${profile_id}`, {

        })
        return data
    }

    async getBalance(profile_id: number = this.externalUser.tenantProfileId!) {
        const {data} = await this.request.post<BalanceResponse>("/api/tenant-transactions/balance", {
            tenantProfileId: profile_id,
        })
        return data
    }

    async getEmplBalance(profile_id: number = this.externalUser.tenantProfileId!) {
        const {data} = await this.request.post<BalanceResponse>("/api/transactions/balance", {
            tenantProfileId: profile_id,
        })
        return data
    }

    async getNormalizeTransactions() {
        const transactions = (await this.getTransactions()).sort((a, b) => b.createdAt - a.createdAt)
        // const salesUnits = await this.getSalesUnits()
        // const onlineShops = await this.getOnlineShops()
        const transactionList: (TransactionProps & SortableItemInterface)[] = []

        for (const transaction of transactions) {
            // const vendor = salesUnits.find(unit => unit.id === transaction.vendorId)
            // const onlineShop = onlineShops.find(shop => shop.id === transaction.onlineShopId)

            let shopName = transaction?.vendor?.nameSf ?? (transaction?.vendor?.name ?? "-")

            if (transaction.onlineShop) {
                shopName = transaction?.onlineShop?.nameSf ?? (transaction?.onlineShop?.name ?? "-")
            }

            // if (vendor) {
            //     shopName = vendor.name
            // }
            //
            // if (onlineShop) {
            //     shopName = onlineShop.nameSf ? onlineShop.nameSf : onlineShop.name
            // }

            // console.log(transaction, shopName, { vendor, onlineShop })


            let expectedReceiptDate: string|undefined = undefined

            if (transaction.transactionStatusId && transaction.transactionStatusId === 1 && transaction.expectedReceiptDate) {
                expectedReceiptDate = getMoment(transaction.expectedReceiptDate).format("DD.MM.YYYY")
            }

            transactionList.push({
                id: transaction.id,
                shopName: shopName,
                cashback: transaction.payerCashback,
                date: getMoment(transaction.createdAt).format("HH:mm"),
                note: transaction.note,
                sale: (transaction.payerCashback / transaction.transactionAmount) * 100,
                // sale: transaction.cashbackTerm,
                status_id: transaction.transactionStatusId,
                status: TRANSACTIONS_STATUS_TYPE[transaction.transactionStatusId].ru_name,
                sum: transaction.transactionAmount,
                expectedReceiptDate: expectedReceiptDate,
                dateNum: transaction.createdAt,
                type: transaction.paymentTypeId
            })
        }

        return getSortedByDate(transactionList)
    }

    async getSalesUnits() {
        const {data} = await this.request.post<SalesUnitResponse[]>("/api/sales-units/public-list", {
            countryCodeId: 1
        })
        return data
    }


    async getOnlineShops() {
        const {data} = await this.request.post<OnlineShopResponse[]>("/api/online-shops/public-list", {
            countryCodeId: 1
        })
        return data
    }

    async getSalesUnitsByIds(ids: number[]) {
        const {data} = await this.request.post<SalesUnitResponse[]>("/api/sales-units/get-by-ids", {
            salesUnitIds: ids
        })
        return data
    }

    async getTransactions() {
        const {data} = await this.request.post<TransactionResponse[]>("/api/tenant-profiles/" + this.externalUser.tenantProfileId + "/transactions")
        return data
    }

    static getNormalizeNotifications(payload: NotificationResponse[]): SortedByDateItemsInterface<NotificationCardProps> {
        const notifications = payload.map(item => ({...item, createdAt: getMoment(item.createdAt).unix()})).sort((a, b) => b.createdAt - a.createdAt)

        const notificationList: (NotificationCardProps & SortableItemInterface)[] = []

        for (const notification of notifications) {
            notificationList.push({
                date: getMoment(notification.createdAt).format("HH:mm"),
                id: notification.id,
                text: notification.note,
                title: notification.title,
                vendorName: notification.vendorName,
                isViewed: notification.viewed,
                feedbackCreated: notification.feedbackCreated,
                dateNum: notification.createdAt
            })
        }


        return getSortedByDate(notificationList)
    }

    async getNotifications () {
        const {data} = await this.request.get<NotificationResponse[]>("/api/tenant-profiles/" + this.externalUser.tenantProfileId + "/notifications")

        return data
    }

    async setNotificationView (notification_id: number) {
        const { data } = await this.request.patch(`/api/tenant-notifications/${notification_id}/viewed`)
        return data
    }

    async getUserByPhone (phone: string) {
        const {data} = await this.request.post<FullUserResponse>(`/api/profiles/get-by-phone`, {
            phone: phone
        })

        if (data.id) {
            const { data: tagsResponse } = await this.request.get(`/api/profiles/${data.id}/employee-statistic`)
            data.tags = tagsResponse.finIndicators.tags
        }
        return data
    }

    async getPaymentTypes() {
        const {data} = await this.request.get<PaymentTypeResponse[]>(`/api/payment-types`)
        return data
    }

    async saleRegistration(payload: SaleRegistration) {
        const {data} = await this.request.post<TransactionResponse>(`/api/transactions/sale-registration`, payload)
        return data
    }

    async sendSmsCode(payload: { marketingCampaignId: number, profileId: number, cashbackTerm: number, paymentTypeId: number, phone?: string, salesUnitId: number, transactionAmount: string }) {
        // const {data} = await this.request.post<TransactionResponse>(`/api/authentication/sale-registration-sms-code`, payload)
        // return data
        return BotApi.sendSmsCode(payload)
    }

    async getOrder(orderId: number) {
        const {data} = await this.request.get<OrderResponse>(`/api/tenant-orders/${orderId}`)
        return data
    }

    async getMarketingCampaigns (payload: MarketingCampaignsRequest) {
        const {data} = await this.request.post<MarketingCampaignsResponse[]>("/api/marketing-campaigns/get-all-by-sales-unit-id", payload)
        return data
    }

    async getMarketingCampaignTerms (payload: MarketingCampaignTermsRequest) {
        const {data} = await this.request.post<MarketingCampaignTermsResponse[]>("/api/marketing-campaign-terms/get-all-by-marketing-campaign-id-and-payment-type-id", payload)
        return data
    }

}

export { FreshbackApi }

export default FreshbackApi
