import {RefObject, useEffect} from "react";

export function useOtherBlockClick(callback: () => void, ref: RefObject<HTMLElement>, deps: Array<any>, checkCloses: string | null = null) {
    useEffect(() => {

        function handleClickOutside(event: Event | MouseEvent) {
            //  && (niceClass && !(event.target as HTMLDivElement).classList.contains(niceClass))
            if (ref && ref.current && event.target && !ref.current.contains(event.target as Node)) {
                if (checkCloses) {
                    const target = event.target as HTMLDivElement
                    const closest = target.closest(checkCloses)
                    if (!closest) {
                        callback()
                    }
                } else {
                    callback()
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        document.querySelector(".react-flow")?.addEventListener("click", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.querySelector(".react-flow")?.removeEventListener("click", handleClickOutside)
        };
    }, [ref, callback, ...deps]);
}