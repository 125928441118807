import { getMoment } from "./getMoment";

export interface SortableItemInterface {
    dateNum: number
}

export interface SortedByDateItemsInterface<T> {
    [key: string]: T[]
}

export function getSortedByDate<T extends SortableItemInterface>(items: T[]): SortedByDateItemsInterface<T>  {

    const hashMap: SortedByDateItemsInterface<T> = {}

    for (const item of items) {
        const itemDate = getMoment(item.dateNum).format("D MMM YYYY")
        if (!(itemDate in hashMap)) {
            hashMap[itemDate] = []
        }

        hashMap[itemDate].push(item)
    }

    return hashMap

}
