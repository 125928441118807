import styled from "@emotion/styled";

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
`

export {
    Iframe
}

export default Iframe