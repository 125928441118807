import React, {useCallback, useMemo} from "react";
import { ComponentType, useEffect, useRef, useState } from "react";
import { TelegramWebAppModel, TelegramWebAppContext } from "./TelegramWebAppContext";
import {Telegram} from "./types/Telegram";

type TelegramWebAppProps = {
  children: JSX.Element;
  validateHash(hash: string): boolean | Promise<boolean>;
};

export function TelegramWebApp({ children, validateHash }: TelegramWebAppProps) {
  const [isReady, setIsReady] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const tgWebAppStartParamRef = useRef(new URLSearchParams(window.location.search).get("tgWebAppStartParam"));

  const model: TelegramWebAppModel = useMemo(() => {

    return {
      // @ts-ignore
      app: window.Telegram!.WebApp as Telegram.Unsafe.WebApp,
      startParam: tgWebAppStartParamRef.current!,
      isReady
    }
  }, [])



  const onReady = useCallback(() => {
    const hashValidation = validateHash(model.app.initData);
    if (hashValidation instanceof Promise) {
      hashValidation.then(setIsValid).finally(() => setIsReady(true));
    } else {
      setIsValid(hashValidation);
      setIsReady(true);
    }

    let lastTheme = model.app.colorScheme
    document.body.classList.add(`theme-${lastTheme}`)

    model.app.onEvent("themeChanged", function () {
      document.body.classList.remove(`theme-${lastTheme}`)
      lastTheme = model.app.colorScheme
      document.body.classList.add(`theme-${lastTheme}`)
    })
  }, [model])

  useEffect(() => {
    if (isReady && !isValid) throw new Error("Invalid hash");
  }, [isReady, isValid]);



  return (
    <TelegramWebAppScript onLoad={onReady}>
      <TelegramWebAppContext.Provider value={model}>
        {children}
      </TelegramWebAppContext.Provider>
    </TelegramWebAppScript>
  );
}

export function withTelegramWebApp(Component: ComponentType<any>, contextProps: Omit<TelegramWebAppProps, "children">) {
  return function WithTelegramWebApp(props: any) {
    return (
      <TelegramWebApp {...contextProps}>
        <Component {...props} />
      </TelegramWebApp>
    );
  };
}

function TelegramWebAppScript({ children, onLoad }: { children: JSX.Element; onLoad: () => void }) {

  // useEffect(() => {
  //   const tgWebAppScript = document.createElement("script");
  //   tgWebAppScript.src = "https://telegram.org/js/telegram-web-app.js";
  //   tgWebAppScript.onload = onLoad;
  //
  //   document.head.appendChild(tgWebAppScript);
  //   (window as any).__TELEGRAM_WEB_APP_SCRIPT_INJECTED__ = true;
  //
  //   return () => {
  //     document.head.removeChild(tgWebAppScript);
  //     (window as any).__TELEGRAM_WEB_APP_SCRIPT_INJECTED__ = false;
  //   };
  // }, []);

  useEffect(() => {
    onLoad()
  }, [])

  return children;
}
