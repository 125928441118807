import create from "zustand";
import {devtools} from "zustand/middleware";
import {ReactElement} from "react";

interface PageHashInterface {
    [key: string]: {
        component: ReactElement,
        title?: string,
        badgeCount?: number,
        icon: ReactElement,
        label?: string,
    }
}

interface ActivePageStoreInterface {
    activePageId: string,
    pages: PageHashInterface,
    setPages: (state: PageHashInterface) => any,
    setActivePage: (state: string) => any,
    ActiveComponent: () => ReactElement | string,

    setBadgeCount: (page_id: string, badgeCount: number) => any

}

export const useActivePage = create(devtools<ActivePageStoreInterface>((set, get) => ({
    activePageId: "MainPage",
    pages: {},
    setPages: (state) => set({pages: state}),
    setActivePage: (state) => {
        if (get().activePageId !== state) {
            set({activePageId: state})
            const parent = document.querySelector("#root > div")
            if (parent) parent.scrollTo(0, 0)
        }
    },

    ActiveComponent: () => {
        const page = get().pages[get().activePageId]
        return page ? page.component : "Страница в разработке"
    },

    setBadgeCount: (page_id, badgeCount) => {
        const pages = get().pages
        if (!pages || !pages[page_id]) return;

        pages[page_id].badgeCount = badgeCount

        set({
            pages: pages
        })
    }
})))