import {useEffect, useRef, useState} from "react";

export function useDebounce(callback: any, delay: number = 500, deps: Array<any> = []) {

    const timer = useRef<any | null>(null)
    const [deps_] = useState(deps ?? [])

    useEffect((...args: any) => {
        if (timer.current) {
            clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }, deps)
}
