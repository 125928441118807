import create from "zustand";
import {devtools} from "zustand/middleware";
import {SalesUnitResponse} from "../../http";

interface SalesUnitsStoreInterface {
    salesUnits: SalesUnitResponse[],
    setSalesUnits: (state: SalesUnitResponse[]) => any
}

export const useSalesUnits = create(devtools<SalesUnitsStoreInterface>((set, get) => ({
    salesUnits: [],
    setSalesUnits: (state) => set({salesUnits: state})
})))