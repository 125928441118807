import React, {useCallback, useEffect} from "react";
import {useNotifications} from "../../stores/useNotifications";
import {AppMessage, NotificationCard, TransactionList, TransactionListDate} from "../../../components";
import {useFreshbackApi} from "../../stores/useFreshbackApi";
import {NotificationCardProps} from "../../../components/NotificationCard/types";
import {useActivePage} from "../../stores/useActivePage";
import IconNote from "../../../icons/IconNote";
import {Loader} from "../../../components/Loader";

export const NotificationPage: React.FC = () => {
    const api = useFreshbackApi((state) => state.api)
    const {notifications, normalizedNotifications, loading, setLoading, setNotifications} = useNotifications()
    const {setBadgeCount} = useActivePage()

    useEffect(() => {
        api.getNotifications().then((response) => {
            setNotifications(response)
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        setBadgeCount("NotificationPage", notifications.filter(item => !item.viewed).length)
    }, [notifications])

    const onView = useCallback((item: NotificationCardProps) => {
        api.setNotificationView(item.id).then(() => {
            setNotifications(notifications.map((_item) => {
                if (_item.id === item.id) {
                    return {
                        ..._item,
                        viewed: true
                    }
                }
                return _item
            }))
            // api.getNotifications().then(response => {
            //
            // })
        })
    }, [notifications])

    if (loading) {
        return <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Loader withoutContainer />
        </div>
    }

    if (Object.keys(normalizedNotifications).length === 0) {
        return <AppMessage text={"У вас нет уведомлений"} icon={<IconNote color={"var(--link-color)"} />}/>
    }

    return <TransactionList>
        {   Object.keys(normalizedNotifications).map((notification_date) => {

                return <React.Fragment key={notification_date}>
                    <TransactionListDate>{notification_date}</TransactionListDate>
                    {normalizedNotifications[notification_date].map(notification => {
                        return <NotificationCard key={notification.id} {...notification} onView={onView} />
                    })}
                </React.Fragment>
            })
        }
    </TransactionList>
}
