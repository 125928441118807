import React from 'react';
import InputMask from "react-input-mask";
import {InputProps} from "../Input";
import Input from "../../components/Input";

// const StyledInput = styled.input`
//   display: inline-block;
//   border-style: solid;
//   border-width: 0 0 1px 0;
//   outline: none;
//   border-color: var(--color-gray-light);
//   font-family: inherit;
//   min-width: 100px;
//   width: 100%;
//   min-height: 40px;
//   height: 100%;
//   font-size: 14px;
//   &:focus, &:active {
//     border-color: var(--button-color);
//   }
// `

const PhoneInput: React.FunctionComponent<InputProps> = (props) => {
    return <>
        <InputMask mask={"+7 999 999 99 99"} value={props.value} onChange={props.onChange}>
            {// @ts-ignore
                () => <Input {...props} type="tel"/>}
        </InputMask>
    </>
};

export default PhoneInput;