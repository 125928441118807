import React, {useMemo, useState} from "react";
import styled from "@emotion/styled";
import {NotificationCardProps, NotificationCardPropsEvents} from "./types";
import {ButtonLink} from "../ButtonLink";
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import IconBar from "../../icons/IconBar";
import IconLinkExternal from "../../icons/IconLinkExternal";
import {OrderFeedback} from "../../features";

const CardContainer = styled.div<{clamp: boolean}>`
  margin: var(--space-gap-3x) 0;
  cursor: ${props => props.clamp ? "pointer" : "auto"};
  user-select: ${props => props.clamp ? "none" : "text"};
`
const Wrapper = styled.div`
  position: relative;
`
const CardDate = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: var(--hint-color);
`
const CardAddColumnContainer = styled.div`
  position: relative;
  border-radius: var(--border-radius-normal);
  padding: var(--space-gap-3x);
  background: var(--secondary-bg-color);
`

const CardTitle = styled.div<{viewed?: boolean, clamp: boolean}>`
  font-size: 16px;
  font-weight: ${ ({viewed}) => !viewed ? "700" : "500"};
  margin-bottom: var(--space-gap);
  color: var(--text-color);
  // -webkit-line-clamp: ${props => props.clamp ? "1" : "initial"};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
`

const CardText = styled.div<{clamp: boolean, viewed?: boolean}>`
  font-size: 14px;
  color: var(--link-color);
  //-webkit-line-clamp: ${props => props.clamp ? "1" : "initial"};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  color: ${ ({viewed}) => !viewed ? "var(--text-color)" : "var(--hint-color)"};
`
const CardStatusCircle = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  background-color: var(--link-color);
  width: 11px;
  height: 11px;
  box-shadow: 0 0 0 3px var(--bg-color);
  border-radius: 20px;
  z-index: 1;
`

const CardTitleContainer = styled.div`
  display: flex;
  gap: var(--space-gap-2x)
`

export const NotificationCard: React.FC<NotificationCardProps & NotificationCardPropsEvents> = ({ id, feedbackCreated, vendorName, title, text, date, isViewed, onView }) => {
    const [isClamp, setIsClamp] = useState<boolean>(true)

    const correctedTest = useMemo(() => {
        const regex = /(\{button\}\[(.*?)\]\((.*?)\)\{\/button\})/gm

        if (regex.test(text)) {
            regex.lastIndex = 0

            const matched = Array.from(text.matchAll(regex))

            regex.lastIndex = 0
            return {
                replaced: true,
                text: text.replaceAll(regex, ""),
                buttons: matched.map((matched, key) => {
                    const feedbackMath = /add-order-feedback\?id=(\d+)/gm.exec(matched[3])
                    if (feedbackMath) {
                        if (feedbackCreated) {
                            return undefined
                        }
                        return <div style={{marginTop: "var(--space-gap-2x)"}}>
                            <OrderFeedback orderId={parseInt(feedbackMath[1])} vendorName={vendorName}/>
                        </div>
                    }
                    return <ButtonLink style={{marginTop: "var(--space-gap-2x)"}} key={key} href={matched[3]}
                                       target={"_blank"}>
                        <IconLinkExternal width={15}/>
                        {matched[2]}
                    </ButtonLink>
                }).filter(item => item)
            }
        }

        return {
            replaced: false,
            text: text,
            buttons: []
        }
    }, [text, vendorName])

    return <CardContainer
        onClick={() => {
            if (isClamp) {
                setIsClamp(!isClamp)

                if (!isViewed) {
                    onView({
                        date: date,
                        id: id,
                        text: text,
                        title: title,
                        vendorName: vendorName,
                        feedbackCreated: feedbackCreated,
                        isViewed: isViewed
                    })
                }
            }
        }}
        clamp={isClamp}>
        <Wrapper>
            <CardAddColumnContainer>
                {!isViewed && <CardStatusCircle />}

                <CardTitleContainer>
                    <CardTitle clamp={isClamp} viewed={isViewed}>{title}</CardTitle>
                    <CardDate>{date}</CardDate>
                </CardTitleContainer>

                <CardText clamp={isClamp} viewed={isViewed}>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        children={correctedTest.text}
                        components={{
                            a: ({node, ...props}) => <a target={"_blank"} {...props} />
                        }}
                    />
                    {correctedTest.buttons.map((button, key) => {
                        return <React.Fragment key={key}>{button}</React.Fragment>
                    })}

                </CardText>

            </CardAddColumnContainer>
        </Wrapper>
    </CardContainer>
}
